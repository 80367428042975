import React, { useEffect } from "react";
import { Card, CardContent, Typography, Divider, Grid, makeStyles, createStyles, Theme, Box } from "@material-ui/core";
import { useParams, useHistory } from "react-router-dom";
import { useGetTransaction } from "../../../services/shop.service";
import { ErrorComponent } from "../../error/ErrorComponent";
import { ProgressComponent } from "../../../components/Shared/Progress/ProgressComponent";
import { StoreActions, useGlobalStoreDispatch } from "../../../storage/GlobalStoreContext";
import { useTracker } from "../../../analytics/trackerContext";
import { FetchStatus } from "../../../services/useFetchApiData";

interface IReceiptContainerProps {}
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        table: { margin: theme.spacing(1, 0, 1, 0) },
    }),
);

export const ReceiptContainer = () => {
    const classes = useStyles();

    const tracking = useTracker();
    const history = useHistory();
    let { sessionId, isCallback } = useParams<{ sessionId: string | undefined; isCallback: string | undefined }>();

    let param = sessionId ? sessionId : "";

    const [status, error, receipt] = useGetTransaction(param);

    const dispatch = useGlobalStoreDispatch();

    useEffect(() => {
        dispatch({ type: StoreActions.SetPageTitle, payload: "Receipt" });
    }, [dispatch]);

    useEffect(() => {
        if (status === FetchStatus.Success && receipt && isCallback) {
            tracking.trackAction({
                eventAction: "Purchase Completed",
                eventCategory: "Shop",
                eventLabel: receipt.Name,
                eventValue: +receipt.Amount, //+ converts to number if null it will be (0)
            });

            history.replace(`/shop/receipt/${sessionId}`);
        }
    }, [isCallback, status, tracking, history, sessionId, receipt]);

    return (
        <>
            <Card>
                {status === FetchStatus.Loading ? (
                    <ProgressComponent text="Loading..." />
                ) : status === FetchStatus.Error || !receipt ? (
                    <ErrorComponent error={error} />
                ) : (
                    <CardContent>
                        <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                            PAYMENT RECEIPT
                        </Typography>
                        <Typography variant="h4" component="h2" gutterBottom>
                            {receipt.Name}
                        </Typography>
                        <Box mt={4} mb={4}>
                            <Typography variant="body2" gutterBottom>
                                Hi,
                            </Typography>
                            <Typography variant="body2">
                                Congratulations! You have just gained access to the {receipt.Name} package.
                            </Typography>
                            <Typography variant="body2">Below are the details of your purchase.</Typography>
                        </Box>
                        <Divider variant="fullWidth" />
                        <Grid container spacing={4} className={classes.table}>
                            <Grid item>
                                <Typography variant="overline" color="textSecondary">
                                    ITEM
                                </Typography>
                                <Typography gutterBottom>{`${receipt.Name} ${receipt.Description}`}</Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="overline" color="textSecondary">
                                    DATE PURCHASED
                                </Typography>
                                <Typography gutterBottom>{`${receipt.Created}`}</Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="overline" color="textSecondary">
                                    AMOUNT
                                </Typography>
                                <Typography gutterBottom>{`${receipt.Amount} ${receipt.Currency}`}</Typography>
                            </Grid>
                        </Grid>
                        <Divider variant="fullWidth" />
                        <Box mt={4}>
                            <Typography variant="caption" color="textSecondary">
                                Invoice id: {receipt.TransactionId} {receipt.Provider}
                            </Typography>
                        </Box>
                    </CardContent>
                )}
            </Card>
        </>
    );
};

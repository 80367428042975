import React from "react";
import { Grid } from "@material-ui/core";
import { ProgressComponent } from "../../components/Shared/Progress/ProgressComponent";
import { OverviewComponent } from "./OverviewComponent";
import { InsightCategoryComponent } from "./InsightCategoryComponent";
import { RadarRankingComponent } from "./RadarRankingComponent";
import { ErrorComponent } from "../error/ErrorComponent";
import { useEffect } from "react";
import { useGetInsights } from "../../services/insights.service";
import { StoreActions, useGlobalStoreDispatch } from "../../storage/GlobalStoreContext";
import { FetchStatus } from "../../services/useFetchApiData";
import { InsightCategoryViewModel } from "../../models/preplabModels";

export const InsightsContainer = () => {
    const [status, error, data] = useGetInsights();
    const dispatch = useGlobalStoreDispatch();

    useEffect(() => {
        dispatch({ type: StoreActions.SetPageTitle, payload: "Insights" });
    }, [dispatch]);

    return (
        <>
            <Grid container spacing={2} direction="column">
                {status === FetchStatus.Loading ? (
                    <ProgressComponent text="Comparing results" />
                ) : status === FetchStatus.Error ? (
                    <ErrorComponent error={error} />
                ) : (
                    <>
                        <Grid item>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    {data && <OverviewComponent insight={data} />}
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {data && <RadarRankingComponent insight={data} />}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container spacing={2}>
                                {data?.Categories &&
                                    data.Categories.map((cat: InsightCategoryViewModel) => (
                                        <Grid key={cat.Id} item xs={12} sm={6}>
                                            <InsightCategoryComponent category={cat} />
                                        </Grid>
                                    ))}
                            </Grid>
                        </Grid>
                    </>
                )}
            </Grid>
        </>
    );
};

import { useFetchPostApiData } from "./useFetchPostApiData";
import {
    PayPalCreateOrderCommand,
    PayPalProcessOrderCommand,
    StripeCreateCheckoutSessionCommand,
    TransactionViewModel,
    UserPackageViewModel,
} from "./../models/preplabModels";
import { useFetchApiData } from "./useFetchApiData";
import { PackagesViewModel } from "../models/preplabModels";

export const useGetTransaction = (transactionId: string) => {
    return useFetchApiData<TransactionViewModel>(`/api/shop/GetTransaction?transactionId=${transactionId}`);
};

export const useGetShop = () => {
    return useFetchApiData<PackagesViewModel>("/api/Shop/GetPackages");
};

export const useGetUserPackages = () => {
    return useFetchApiData<UserPackageViewModel>("/api/Shop/GetUserPackages");
};

export const useCreateCheckoutSession = () => {
    return useFetchPostApiData<StripeCreateCheckoutSessionCommand, string>("/api/payment/stripe/CreateCheckoutSession");
};

export const useCreatePayPalOrder = () => {
    return useFetchPostApiData<PayPalCreateOrderCommand, string>("/api/payment/paypal/CreateOrder");
};

export const useProcessPayPalOrder = () => {
    return useFetchPostApiData<PayPalProcessOrderCommand, string>("/api/payment/paypal/ProcessOrder");
};

import React from "react";
import {
    Typography,
    AccordionSummary,
    AccordionDetails,
    AccordionActions,
    Card,
    CardContent,
    Button,
    Grid,
    FormLabel,
    makeStyles,
    Theme,
    createStyles,
    CardMedia,
    Tooltip,
    Accordion,
} from "@material-ui/core";
import { TestSessionHelper } from "../../common/testSessionHelper";
import { QuestionFeedbackDialog } from "../../components/Shared/QuestionFeedback/QuestionFeedbackComponent";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { SingleOptionSelectionList } from "../practice/TestSession/SingleOptionSelectionList";
import { MostLeastOptionSelectionList } from "../practice/TestSession/MostLeastOptionSelectionList";
import { useState } from "react";
import FeedbackOutlinedIcon from "@material-ui/icons/FeedbackOutlined";
import { green, yellow, red } from "@material-ui/core/colors";
import { PlayerDialog } from "../../components/VideoPlayer/PlayerDialog";
import { SessionResultCategoryViewModel } from "../../models/preplabModels";

const useStyles: any = makeStyles((theme: Theme) =>
    createStyles({
        appBar: {
            position: "relative",
        },
        title: {
            marginLeft: theme.spacing(2),
            flex: 1,
        },
        grid: {
            marginTop: theme.spacing(2),
        },
        card: {},
        media: {
            height: "100%",
            width: "100%",
        },
        textDefault: {
            color: theme.palette.primary.main,
        },
        textCorrect: {
            color: green.A700,
        },
        textIncorrect: {
            color: red.A700,
        },
        textPartial: {
            color: yellow.A700,
        },
    }),
);

interface IResultQuestionsProps {
    testSessionResult: SessionResultCategoryViewModel;
}

export const ResultQuestionsComponent = ({ testSessionResult }: IResultQuestionsProps) => {
    const classes = useStyles();

    const [openFeedback, setOpenFeedback] = useState({ open: false, questionId: "", questionName: "" });
    const [openVideoPlayer, setOpenVideoPlayer] = useState({ open: false, videoUrl: "" });

    const showFeedbackDialog = (questionId: string, questionName: string) => {
        setOpenFeedback({ open: true, questionId: questionId, questionName: questionName });
    };

    const showVideoPlayer = (videoUrl: string) => {
        setOpenVideoPlayer({ open: true, videoUrl: videoUrl });
    };

    const closeFeedbackDialog = () => {
        setOpenFeedback({ ...openFeedback, open: false });
    };

    const getColor = (state: number) => {
        switch (state) {
            case 0:
                return classes.textIncorrect;
            case 1:
                return classes.textCorrect;
            case 2:
                return classes.textPartial;
            default:
                return classes.textDefault;
        }
    };

    return (
        <>
            {testSessionResult.Questions.map((question, index) => (
                <Accordion key={question.Id}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Grid container direction="row" alignItems="center">
                            <Grid item xs={6}>
                                <Typography
                                    variant="subtitle2"
                                    gutterBottom
                                    noWrap
                                    dangerouslySetInnerHTML={{
                                        __html: `${index + 1}. ${question.Body.trim()
                                            .replace(/<(?:[^><\"\']*?(?:([\"\']).*?\1)?[^><\'\"]*?)+(?:>|$)/g, "") //eslint-disable-line
                                            .slice(0, 60)
                                            .trim()}...`,
                                    }}
                                ></Typography>
                                {question.IsViewed ? (
                                    question.IsAnswered ? (
                                        <>
                                            <FormLabel component="legend">
                                                <span className={getColor(question.State)}>
                                                    {TestSessionHelper.getQuestionLabelByState(question.State)}
                                                </span>
                                            </FormLabel>
                                        </>
                                    ) : (
                                        <>
                                            <FormLabel component="legend">Question unanswered</FormLabel>
                                        </>
                                    )
                                ) : (
                                    <>
                                        <FormLabel component="legend">Question not viewed</FormLabel>
                                    </>
                                )}
                            </Grid>
                            <Grid item xs={2}>
                                <Tooltip title="Time you have spent">
                                    <FormLabel component="legend">Time</FormLabel>
                                </Tooltip>
                                <Typography variant="body2">
                                    {question.TimeSpentMs ? (
                                        <>{Math.round(question.TimeSpentMs / 1000)} s</>
                                    ) : (
                                        <>{0} s</>
                                    )}
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Tooltip title="Average time users have spent on this question and answered correctly">
                                    <FormLabel component="legend">Avg. Time</FormLabel>
                                </Tooltip>
                                <Typography variant="body2">
                                    {question.AvgDuration ? <>{question.AvgDuration}s</> : <>{0}s</>}
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Tooltip title="Other users score">
                                    <FormLabel component="legend">Other users</FormLabel>
                                </Tooltip>
                                <Typography variant="body2">
                                    {question.Percent ? <>{question.Percent}%</> : <>{0}%</>}
                                </Typography>
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails style={{ backgroundColor: "rgba(0, 0, 0, .03)" }}>
                        <Grid container justify="space-between" spacing={2}>
                            <Grid item xs={12} md={5}>
                                <Card className={classes.card}>
                                    <CardContent>
                                        <Typography
                                            variant="body1"
                                            dangerouslySetInnerHTML={{ __html: `${question.Body}` }}
                                        />
                                    </CardContent>
                                    {question.Image && (
                                        <CardMedia>
                                            <img src={question.Image} alt={question.Title} className={classes.media} />
                                        </CardMedia>
                                    )}
                                </Card>
                            </Grid>
                            <Grid item xs={12} md={7}>
                                {question && question.IsSingleCorrect && (
                                    <SingleOptionSelectionList
                                        options={question.Options}
                                        isExplanationMode={true}
                                        mostEffectiveAnswerId={question.MostEffectiveAnswer}
                                        onSelect={() => null}
                                        onPlayVideo={showVideoPlayer}
                                    />
                                )}

                                {question && !question.IsSingleCorrect && (
                                    <MostLeastOptionSelectionList
                                        options={question.Options}
                                        isExplanationMode={true}
                                        mostEffectiveAnswerId={question.MostEffectiveAnswer}
                                        leastEffectiveAnswerId={question.LessEffectiveAnswer}
                                        onSelectMostEffective={() => null}
                                        onSelectLeastEffective={() => null}
                                        onPlayVideo={showVideoPlayer}
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                    <AccordionActions>
                        <Button
                            onClick={() => showFeedbackDialog(question.Id, question.Title)}
                            endIcon={<FeedbackOutlinedIcon />}
                        >
                            Feedback
                        </Button>
                    </AccordionActions>
                </Accordion>
            ))}
            <QuestionFeedbackDialog
                open={openFeedback.open}
                questionId={openFeedback.questionId}
                questionName={openFeedback.questionName}
                onClose={closeFeedbackDialog}
            />
            <PlayerDialog
                open={openVideoPlayer.open}
                title="Explanation video"
                videoUrl={openVideoPlayer.videoUrl}
                onClose={() => setOpenVideoPlayer({ ...openVideoPlayer, open: false })}
            />
        </>
    );
};

import React from "react";
import { Grid, Card, CardContent, Typography, CardActions } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { PracticeButton } from "./PracticeButton";
import { TestSessionType, UserPackageCategoryViewModel } from "../../models/preplabModels";

interface IPracticeMultipleComponentProps {
    selectItem: (mode: TestSessionType) => void;
}

export const PracticeMultipleComponent = ({ selectItem }: IPracticeMultipleComponentProps) => {
    return (
        <>
            <Card>
                <CardContent style={{ background: "#ffa801", color: "#fff" }}>
                    <Grid container spacing={0}>
                        <Grid item xs={10} className="">
                            <Typography variant="h6" color="inherit">
                                Combine categories
                            </Typography>
                            <Typography variant="body2" color="inherit">
                                Cover multiple areas in single practice session
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
                <CardActions classes={{ root: "category-action" }}>
                    <Grid container spacing={0}>
                        <Grid item>
                            <PracticeButton
                                tooltip="No time limit practice"
                                onClick={() => selectItem(TestSessionType.Practice)}
                            >
                                Practice
                            </PracticeButton>
                        </Grid>

                        <Grid item>
                            <PracticeButton
                                tooltip={"Practice your weaknesses"}
                                onClick={() => selectItem(TestSessionType.Weaknesses)}
                            >
                                Weaknesses
                            </PracticeButton>
                        </Grid>

                        <Grid item>
                            <PracticeButton tooltip="Timed exam" onClick={() => selectItem(TestSessionType.Exam)}>
                                Exam
                            </PracticeButton>
                        </Grid>
                    </Grid>
                </CardActions>
            </Card>
        </>
    );
};

interface IPracticeMultipleSelectorDialogProps {
    categories: Array<UserPackageCategoryViewModel>;
    testsessiontype: TestSessionType;
    open: boolean;
    selectItems: (categories: Array<UserPackageCategoryViewModel>) => void;
    onClose: () => void;
}

export const PracticeMultipleSelectorDialog = ({
    categories,
    testsessiontype,
    open,
    selectItems,
    onClose,
}: IPracticeMultipleSelectorDialogProps) => {
    let selectedCategories = [] as Array<UserPackageCategoryViewModel>;

    const handleStart = () => {
        selectItems(selectedCategories);
    };

    const handleClose = () => onClose();

    const handleChange = (category: UserPackageCategoryViewModel, checked: boolean) => {
        if (checked) {
            selectedCategories = selectedCategories.concat(category);
        } else {
            let index = selectedCategories.findIndex((cat) => cat.Id === category.Id);

            if (index > -1) {
                selectedCategories.splice(index, 1);
            }
        }
    };

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Select one or more categories</DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={2}>
                        {categories &&
                            categories.map((category: UserPackageCategoryViewModel) => (
                                <Grid item xs={12} md={6} key={category.Id}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name={category.Id}
                                                color="primary"
                                                disabled={
                                                    testsessiontype === TestSessionType.Weaknesses
                                                        ? !category.CanPracticeWeaknesses
                                                        : false
                                                }
                                                onChange={(event) => handleChange(category, event.target.checked)}
                                            />
                                        }
                                        label={category.Name}
                                    />
                                </Grid>
                            ))}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleStart} color="primary" autoFocus>
                        Start
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

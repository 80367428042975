import React from "react";
import {
    List,
    ListItem,
    ListItemText,
    Divider,
    ListItemIcon,
    Typography,
    Radio,
    makeStyles,
    createStyles,
    Theme,
} from "@material-ui/core";
import { green, red, yellow } from "@material-ui/core/colors";

import WrongIcon from "@material-ui/icons/ClearOutlined";
import WrongSelectedIcon from "@material-ui/icons/CancelOutlined";

import CorrectSelectedIcon from "@material-ui/icons/CheckCircleOutlined";
import CorrectIcon from "@material-ui/icons/CheckOutlined";
import { OptionExplanation } from "./OptionExplanation";
import { SessionResultOptionViewModel } from "../../../models/preplabModels";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        radioDefault: {},

        radioIncorrect: {
            color: red[700],
            "&$checked": {
                color: red[500],
            },
        },
        radioCorrect: {
            color: green[700],
            "&$checked": {
                color: green[500],
            },
        },

        radioPartial: {
            color: yellow[400],
            "&$checked": {
                color: yellow[500],
            },
        },

        checked: {},
    }),
);

interface ISingleOptionItemProp {
    option: SessionResultOptionViewModel;
    selected: boolean;
    isExplanationMode?: boolean;
    onPlayVideo?: (url: string) => void;
    onSelected: (optionId: string) => void;
}

const SingleOptionItem = ({ option, isExplanationMode, selected, onPlayVideo, onSelected }: ISingleOptionItemProp) => {
    const classes = useStyles();

    const getResultIcon = (sel: boolean, res: SessionResultOptionViewModel) => {
        if (!isExplanationMode) {
            return <></>;
        }

        switch (res.MostEffectiveState) {
            case 0:
                return sel === true ? (
                    <WrongSelectedIcon className={getColor(res)} />
                ) : (
                    <WrongIcon className={getColor(res)} />
                );
            case 1:
                return sel === true ? (
                    <CorrectSelectedIcon className={getColor(res)} />
                ) : (
                    <CorrectIcon className={getColor(res)} />
                );
            default:
                return <></>;
        }
    };

    const getColor = (res: SessionResultOptionViewModel) => {
        if (!isExplanationMode) return classes.radioDefault;

        switch (res.MostEffectiveState) {
            case 0:
                return classes.radioIncorrect;
            case 1:
                return classes.radioCorrect;
            case 2:
                return classes.radioPartial;
            default:
                return classes.radioDefault;
        }
    };

    const handleOpenVideo = () => {
        if (onPlayVideo && option.ExplanationVideo) onPlayVideo(option.ExplanationVideo);
    };

    return (
        <>
            <ListItem
                alignItems="flex-start"
                button={!isExplanationMode as any}
                onClick={() => {
                    onSelected(option.Id);
                }}
            >
                <ListItemIcon>
                    {isExplanationMode ? (
                        getResultIcon(selected, option)
                    ) : (
                        <Radio
                            checked={selected}
                            edge="start"
                            tabIndex={-1}
                            disableRipple
                            classes={{ root: getColor(option), checked: classes.checked }}
                        />
                    )}
                </ListItemIcon>
                <ListItemText>
                    <Typography gutterBottom>{option.Text}</Typography>

                    {isExplanationMode && (
                        <OptionExplanation
                            explanation={option.Explanation}
                            explanationVideo={option.ExplanationVideo}
                            openVideo={handleOpenVideo}
                        />
                    )}
                </ListItemText>
            </ListItem>
        </>
    );
};

interface ISingleOptionSelectionListProps {
    options: SessionResultOptionViewModel[];
    mostEffectiveAnswerId: string;
    isExplanationMode: boolean;
    onPlayVideo?: (url: string) => void;
    onSelect: (oId: string) => void;
}

export const SingleOptionSelectionList = ({
    options,
    isExplanationMode,
    mostEffectiveAnswerId,
    onPlayVideo,
    onSelect,
}: ISingleOptionSelectionListProps) => {
    const handleListItemClick = (oId: string) => {
        onSelect(oId);
    };

    return (
        <>
            <List>
                {options.map((item, index) => (
                    <React.Fragment key={item.Id}>
                        <SingleOptionItem
                            option={item}
                            selected={mostEffectiveAnswerId === item.Id}
                            onSelected={handleListItemClick}
                            isExplanationMode={isExplanationMode}
                            onPlayVideo={onPlayVideo}
                        />
                        {options[index + 1] ? <Divider /> : ""}
                    </React.Fragment>
                ))}
            </List>
        </>
    );
};

import React from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import { useAuth0 } from "../../auth/auth0Context";

export const AuthRoute = ({ component, ...rest }: any) => {
    const { isAuthenticated } = useAuth0();
    const location = useLocation();
    const routeComponent = (props: any) =>
        isAuthenticated ? (
            React.createElement(component, props)
        ) : (
            <Redirect to={{ pathname: "/login", state: { origin: location.pathname } }} />
        );

    return <Route {...rest} render={routeComponent} />;
};

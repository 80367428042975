import React from "react";
import { Grid, List, ListItem, Divider, ListItemText } from "@material-ui/core";
import { ISelectedPackage } from "../../../models/ISelectedPackage";

export const ReceiptSummaryItem = ({ selectedPackage }: { selectedPackage: ISelectedPackage }) => {
    return (
        <>
            <List>
                <ListItem>
                    <Grid container alignContent="stretch">
                        <Grid item>
                            <ListItemText
                                primary={selectedPackage.name}
                                secondary={`Duration ${selectedPackage.description}`}
                            ></ListItemText>
                        </Grid>
                        <Grid item xs></Grid>
                        <Grid item>
                            <ListItemText
                                primary={`${selectedPackage.price} ${selectedPackage.currency}`}
                                secondary=" "
                            ></ListItemText>
                        </Grid>
                    </Grid>
                </ListItem>
                <Divider component="li" />
                <ListItem>
                    <Grid container alignContent="stretch">
                        <Grid item>
                            <ListItemText primary="TOTAL AMOUNT" secondary="VAT included"></ListItemText>
                        </Grid>
                        <Grid item xs></Grid>
                        <Grid item>
                            <ListItemText
                                primary={`${selectedPackage.price} ${selectedPackage.currency}`}
                                secondary=" "
                            ></ListItemText>
                        </Grid>
                    </Grid>
                </ListItem>
            </List>
        </>
    );
};

import React, { useEffect, useState } from "react";
import { useAuth0 } from "../../auth/auth0Context";
import { Redirect, useLocation } from "react-router-dom";

export const LoginContainer = () => {
    const { isAuthenticated } = useAuth0();
    const location = useLocation();

    const [origin, setOrigin] = useState("/practice");

    useEffect(() => {
        let state = location.state as { origin: string };

        if (state?.origin) {
            setOrigin(state.origin);
        }
    }, [location]);

    return <>{isAuthenticated && <Redirect to={{ pathname: origin }} />}</>;
};

import React from "react";
import { ListItemIcon, MenuItem, Typography, Icon } from "@material-ui/core";
import { Link, LinkProps } from "react-router-dom";

interface IMenuItemProps {
    title: string;
    to: string;
    icon: string;
    onClick?: () => void;
}

export const SideBarMenuItem = ({ title, to, icon, onClick }: IMenuItemProps) => {
    return (
        <>
            <MenuItem component={AdapterLink} to={to} onClick={onClick}>
                <ListItemIcon>
                    <Icon>{icon}</Icon>
                </ListItemIcon>
                <Typography variant="inherit">{title}</Typography>
            </MenuItem>
        </>
    );
};

// Used for react-router-dom link compability
const AdapterLink = React.forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => (
    <Link innerRef={ref as any} {...props} />
));

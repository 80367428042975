import React, { useEffect, useState } from "react";
import { Grid, Typography, LinearProgress } from "@material-ui/core";
import { ISelectedPackage } from "../../../models/ISelectedPackage";
import { PayPalButton } from "react-paypal-button-v2";
import { useCreatePayPalOrder, useProcessPayPalOrder } from "../../../services/shop.service";
import { useHistory } from "react-router";
import { useTracker } from "../../../analytics/trackerContext";
import { PostState } from "../../../services/useFetchPostApiData";

interface IPayPalCheckoutProps {
    selectedPackage: ISelectedPackage;
    paypalPublicKey: string;
}

export const PayPalCheckoutComponent = ({ paypalPublicKey, selectedPackage }: IPayPalCheckoutProps) => {
    const [loading, setLoading] = useState(true);
    const [processing, setProcessing] = useState(false);

    const tracking = useTracker();

    let history = useHistory();

    const [, createOrder] = useCreatePayPalOrder();

    const [procStatus, processOrder] = useProcessPayPalOrder();

    useEffect(() => {
        if (processing && procStatus.status === PostState.Success && procStatus.data) {
            setProcessing(false);

            tracking.trackAction({
                eventAction: "Purchase Completed",
                eventCategory: "Shop",
                eventLabel: selectedPackage.name,
                eventValue: +selectedPackage.price, //+ converts to number if null it will be (0)
            });

            history.push(`/shop/receipt/${procStatus.data}`);
        }
    }, [
        processing,
        procStatus.status,
        procStatus.data,
        tracking,
        selectedPackage.name,
        selectedPackage.price,
        history,
    ]);

    const handleSucess = (details: any, data: any) => {
        setProcessing(true);

        processOrder({
            Amount: +selectedPackage.price,
            Duration: +selectedPackage.duration,
            PackageId: selectedPackage.packageId,
            Name: selectedPackage.name,
            Description: selectedPackage.description,
            OrderId: data.orderID,
        });
    };

    return (
        <>
            <Grid container direction="column">
                {procStatus.status === PostState.Error && (
                    <Grid item xs>
                        <Typography color="error">{procStatus.error}</Typography>
                    </Grid>
                )}
                {loading && (
                    <Grid item xs>
                        <Typography align="center">Loading please wait...</Typography>
                        <LinearProgress />
                    </Grid>
                )}
                {processing && (
                    <Grid item xs>
                        <Typography align="center">Processing the transaction do not close the browser</Typography>
                        <LinearProgress />
                    </Grid>
                )}
                <Grid item xs style={{ visibility: processing ? "collapse" : "visible" }}>
                    <PayPalButton
                        style={{
                            height: 45,
                            tagline: false,
                            color: "black",
                        }}
                        amount={selectedPackage.price}
                        currency={selectedPackage.currency}
                        createOrder={async () => {
                            var res = await createOrder({
                                Amount: +selectedPackage.price,
                                PackageId: selectedPackage.packageId,
                                Name: selectedPackage.name,
                                Description: selectedPackage.description,
                            });

                            tracking.trackAction({
                                eventAction: "Purchase Started",
                                eventCategory: "Shop",
                                eventLabel: selectedPackage.name,
                            });

                            return res;
                        }}
                        onSuccess={handleSucess}
                        options={{
                            clientId: paypalPublicKey,
                            currency: selectedPackage.currency,
                        }}
                        onButtonReady={() => setLoading(false)}
                    ></PayPalButton>
                </Grid>
            </Grid>
        </>
    );
};

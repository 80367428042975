export class TestSessionHelper {
    public static getQuestionColorByState(state: number): string {
        return state !== 0 ? (state === 2 ? "#F0E68C" : "#26A65B") : "#C93756";
    }

    public static getQuestionLabelByState(state: number): string {
        return state !== 0 ? (state === 2 ? "Partially correct" : "Correct") : "Incorrect";
    }

    public static getOptionColorByState(
        mostState: number,
        leassState: number,
        mostSelected: number,
        lessSelected: number,
    ): string | undefined {
        if (mostSelected) {
            return mostState === 2 ? "#F0E68C" : mostState === 0 ? "#C93756" : "#26A65B";
        }

        if (lessSelected) {
            return leassState === 2 ? "#F0E68C" : leassState === 0 ? "#C93756" : "#26A65B";
        }

        if (mostState !== 0) {
            return mostState === 2 ? "#fff" : "#26A65B";
        } else if (leassState !== 0) {
            return leassState === 2 ? "#fff" : "#26A65B";
        }

        return undefined;
    }

    public static getLabelByColor(color: string): string {
        switch (color) {
            case "#C93756":
                return "Incorrect";
            case "#26A65B":
                return "Correct";
            case "#F0E68C":
                return "Partial";
            case "#DDDCD8":
                return "Unanswered";
        }

        return "";
    }
}

import React from "react";
import {
    Card,
    Grid,
    Typography,
    Button,
    CardActions,
    Select,
    CircularProgress,
    FormControl,
    MenuItem,
    InputLabel,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from "@material-ui/core";

import { Save } from "@material-ui/icons";
import { useState } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { UserSettingsViewModel, UserCategorySettingsViewModel } from "../../models/preplabModels";

interface IPracticeCategorySettingsComponentProps {
    userSettings: UserSettingsViewModel;
    processing: boolean;
    saveUserSettings: (settings: UserSettingsViewModel) => void;
}
export const PracticeCategorySettingsComponent = (props: IPracticeCategorySettingsComponentProps) => {
    const { userSettings, processing, saveUserSettings } = props;

    const [settings, setSettings] = useState(userSettings);

    const progressStyle = {
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    };

    const languageFormStyle = {
        marginRight: 10,
    };

    const handleLanguageChange = (categoryId: string, value: string) => {
        var catSetting = settings.CategoriesSettings.filter(
            (x: UserCategorySettingsViewModel) => x.CategoryId === categoryId,
        );

        if (catSetting.length > 0) {
            catSetting[0].SelectedLanguage = value;

            setSettings((oldValues) => ({ ...oldValues, catSetting }));
        }
    };

    const handleQuestionLimitChange = (categoryId: string, value: number) => {
        var catSetting = settings.CategoriesSettings.filter(
            (x: UserCategorySettingsViewModel) => x.CategoryId === categoryId,
        );

        if (catSetting.length > 0) {
            catSetting[0].QuestionsLimit = value;

            setSettings((oldValues) => ({ ...oldValues, catSetting }));
        }
    };

    const saveSettings = (event: any) => {
        event.preventDefault();
        saveUserSettings(settings);
    };

    return (
        <>
            {userSettings.CategoriesSettings &&
                userSettings.CategoriesSettings.map((categorySettings, index) => (
                    <Accordion key={index}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>{categorySettings.Name}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container direction="column" spacing={2}>
                                <Grid item>
                                    <FormControl
                                        fullWidth={true}
                                        margin="normal"
                                        disabled={categorySettings.AvailableLanguages.length <= 1}
                                    >
                                        <InputLabel htmlFor={categorySettings.CategoryId + "_language"}>
                                            Language
                                        </InputLabel>
                                        <Select
                                            style={languageFormStyle}
                                            name={categorySettings.CategoryId + "_language"}
                                            data-category-id={categorySettings.CategoryId}
                                            value={categorySettings.SelectedLanguage}
                                            onChange={(e) =>
                                                handleLanguageChange(
                                                    categorySettings.CategoryId,
                                                    e.target.value as string,
                                                )
                                            }
                                        >
                                            {categorySettings.AvailableLanguages.map((lang) => (
                                                <MenuItem key={lang.Code} value={lang.Code}>
                                                    {lang.Name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item>
                                    <FormControl fullWidth={true} margin="normal">
                                        <InputLabel htmlFor={categorySettings.CategoryId + "_limit"}>
                                            Questions per session
                                        </InputLabel>
                                        <Select
                                            name={categorySettings.CategoryId + "_limit"}
                                            data-category-id={categorySettings.CategoryId}
                                            value={categorySettings.QuestionsLimit}
                                            onChange={(e) =>
                                                handleQuestionLimitChange(
                                                    categorySettings.CategoryId,
                                                    e.target.value as number,
                                                )
                                            }
                                        >
                                            {categorySettings.AvailableQuestionsLimit.map((limit) => (
                                                <MenuItem key={limit} value={limit}>
                                                    {limit}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                ))}
            <Card>
                <CardActions>
                    <Button onClick={saveSettings} disabled={processing} startIcon={<Save color="action" />}>
                        {processing ? "Saving..." : "Save Changes"}
                        {processing && <CircularProgress size={24} style={progressStyle} />}
                    </Button>
                </CardActions>
            </Card>
        </>
    );
};

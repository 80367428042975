import React from "react";
import {
    Dialog,
    useTheme,
    useMediaQuery,
    DialogTitle,
    IconButton,
    Typography,
    createStyles,
    Theme,
    makeStyles,
    Divider,
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import { VideoPlayer } from "./VideoPlayerComponent";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: theme.spacing(2),
        },
        closeButton: {
            position: "absolute",
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
    }),
);

interface IPlayerDialogProps {
    title: string;
    videoUrl: string;
    open: boolean;
    onClose?: () => void;
}

export const PlayerDialog = ({ open, title, videoUrl, onClose }: IPlayerDialogProps) => {
    const classes = useStyles();

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const handleClose = () => {
        if (onClose) onClose();
    };

    return (
        <>
            <Dialog open={open} fullScreen={fullScreen} maxWidth="md" onBackdropClick={handleClose}>
                <DialogTitle disableTypography className={classes.root}>
                    <Typography variant="h6">{title}</Typography>
                    <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <VideoPlayer videoUrl={videoUrl} />
            </Dialog>
        </>
    );
};

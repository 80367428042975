import React from "react";
import { IQuestionResult } from "../../../models/IQuestionResult";
import { useEffect, useState, useRef } from "react";
import {
    Dialog,
    Typography,
    Button,
    IconButton,
    AppBar,
    Toolbar,
    makeStyles,
    createStyles,
    Theme,
    Container,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { ProgressComponent } from "../../../components/Shared/Progress/ProgressComponent";
import { ErrorComponent } from "../../error/ErrorComponent";
import { QuestionTabs } from "./QuestionTabs";
import { CountdownComponent } from "../../../components/Shared/Countdown/CountdownComponent";
import { ConfirmDialogComponent } from "../../../components/Shared/ConfirmDialog/ConfirmDialogComponent";
import { useCloseTestSession, useStartTestSession } from "../../../services/testSession.service";
import { useTracker } from "../../../analytics/trackerContext";
import { PostState } from "../../../services/useFetchPostApiData";
import {
    SessionResultQuestionViewModel,
    TestSessionType,
    UserPackageCategoryViewModel,
    CloseTestSessionCommandQuestion,
} from "../../../models/preplabModels";

interface ITestSessionDialogProps {
    category: UserPackageCategoryViewModel[];
    mode: TestSessionType;
    open: boolean;
    onClose: () => void;
    onComplete: (sessionId: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        appBar: {
            position: "relative",
        },
        title: {
            marginLeft: theme.spacing(2),
            flex: 1,
        },
        counter: {
            marginLeft: theme.spacing(2),
            flex: 1,
        },
    }),
);

export const PracticeTestSessionDialog = (props: ITestSessionDialogProps) => {
    const { onClose, onComplete, open, category, mode } = props;

    const classes = useStyles();
    const tracking = useTracker();

    const [selectedTab, setSelectedTab] = useState(0);
    const [result, setResult] = useState<{ [qId: string]: IQuestionResult }>({});

    const [showAbortConfirmation, setShowAbortConfirmation] = useState(false);
    const [showCompleteConfirmation, setShowCompleteConfirmation] = useState(false);
    const [countdownActive, setCountdownActive] = useState(true);

    const [response, startTestSession] = useStartTestSession();

    const [closeResponse, closeSession] = useCloseTestSession();

    const didMount = useRef(false);

    let questions = response?.data?.Categories ? response.data.Categories.flatMap((c) => c.Questions) : [];
    let mins = response?.data?.Categories?.reduce((prev, cur) => prev + cur.MaxTotalTimeS, 0) ?? 0;
    let sessionId = response?.data?.Id ?? null;

    const onCompleteCallback = React.useCallback(onComplete, [sessionId]);

    useEffect(() => {
        startTestSession({ CategoryIds: category.map((c) => c.Id), TestSessionType: mode });
    }, [category, mode, startTestSession]);

    useEffect(() => {
        if (response.status === PostState.Success) {
            tracking.trackAction({
                eventAction:
                    (response?.data?.Categories?.length ?? 0) > 1 ? "Customized Practice Started" : "Practice Started",
                eventCategory: "Practice",
                eventLabel: category.flatMap((c) => c.Name).join(","),
                eventValue: category.flatMap((c) => c.PreferedLanguage).join(","),
            });

            if (mode === TestSessionType.Weaknesses) {
                tracking.trackAction({
                    eventAction: "Weakness Practice Started",
                    eventCategory: "Practice",
                    eventLabel: category.flatMap((c) => c.Name).join(","),
                });
            } else if (mode === TestSessionType.Exam) {
                tracking.trackAction({
                    eventAction: "Exam Practice Started",
                    eventCategory: "Practice",
                    eventLabel: category.flatMap((c) => c.Name).join(","),
                });
            } else {
                tracking.trackAction({
                    eventAction: "Regular Practice Started",
                    eventCategory: "Practice",
                    eventLabel: category.flatMap((c) => c.Name).join(","),
                });
            }
        }
    }, [category, mode, response, response.status, tracking]);

    useEffect(() => {
        if (closeResponse.status === PostState.Success && didMount.current && sessionId) {
            setShowCompleteConfirmation(false);

            tracking.trackAction({
                eventAction: "Practice Completed",
                eventCategory: "Practice",
                eventLabel: category.flatMap((c) => c.Name).join(","),
            });

            onCompleteCallback(sessionId);
        }

        didMount.current = true;
    }, [category, closeResponse.status, onCompleteCallback, sessionId, tracking]);

    const onTabSelected = (selectedTab: number) => {
        // setTimer(new Date());
        setSelectedTab(selectedTab);
    };

    const onTabDeselected = (tab: number, question: SessionResultQuestionViewModel) => {
        // setTimeSpent(question.Id);
    };

    const onAbortTest = () => {
        setShowAbortConfirmation(false);
        onClose();
    };

    const onCompleteTest = () => {
        if (questions && sessionId) {
            let resQ = new Array<CloseTestSessionCommandQuestion>();

            for (let key in result) {
                const item = result[key];
                resQ.push({
                    Id: item.Id,
                    MostEffectiveAnswer: item.MostEffectiveAnswer,
                    LessEffectiveAnswer: item.LessEffectiveAnswer,
                    Duration: item.TimeSpent,
                });
            }

            closeSession({
                SessionId: sessionId,
                Questions: resQ,
            });
        }
    };

    const selectMostEffectiveAnswer = (optionId: string, questionId: string) => {
        setResult((prevState) => ({
            ...prevState,
            [questionId]: {
                ...prevState[questionId],
                Id: questionId,
                MostEffectiveAnswer: optionId,
            },
        }));
    };

    const selectLessEffectiveAnswer = (optionId: string, questionId: string) => {
        setResult((prevState) => ({
            ...prevState,
            [questionId]: {
                ...prevState[questionId],
                Id: questionId,
                LessEffectiveAnswer: optionId,
            },
        }));
    };

    const setTimeSpent = (questionId: string) => {
        let questionTimeSpent = 0;

        if (result[questionId]?.TimeSpent) {
            questionTimeSpent = result[questionId].TimeSpent;
        }

        questionTimeSpent += 1000; //miliseconds

        //questionTimeSpent += Date.now() - timer.getTime();

        setResult((prevState) => ({
            ...prevState,
            [questionId]: {
                ...prevState[questionId],
                Id: questionId,
                TimeSpent: questionTimeSpent,
            },
        }));
    };

    const timerTick = (sec: number) => {
        setTimeSpent(questions[selectedTab].Id);

        if (sec <= 0 && mode === TestSessionType.Exam) {
            setCountdownActive(false);
            onCompleteTest();
        }
    };

    return (
        <>
            <Dialog fullScreen open={open}>
                <AppBar className={classes.appBar}>
                    <Toolbar variant="dense">
                        {mode !== TestSessionType.Exam && (
                            <IconButton
                                edge="start"
                                color="inherit"
                                aria-label="close"
                                onClick={() => setShowAbortConfirmation(true)}
                            >
                                <CloseIcon />
                            </IconButton>
                        )}

                        <Typography variant="h6" className={classes.title}>
                            {mode === TestSessionType.Weaknesses
                                ? "Weaknesses"
                                : mode === TestSessionType.Exam
                                ? "Exam"
                                : "Practice"}
                        </Typography>
                        {response.status === PostState.Success && questions && questions.length > 0 && (
                            <>
                                <CountdownComponent start={countdownActive} sec={mins} timerTick={timerTick} />
                                <Button color="inherit" onClick={() => setShowCompleteConfirmation(true)}>
                                    complete
                                </Button>
                            </>
                        )}
                    </Toolbar>
                </AppBar>

                {response.status === PostState.Sending ? (
                    <Container>
                        <ProgressComponent text="Preparing your test session" />
                    </Container>
                ) : response.status === PostState.Error ? (
                    <Container>
                        <ErrorComponent error={response.error} />
                    </Container>
                ) : (
                    <>
                        {questions && questions.length > 0 && (
                            <>
                                <QuestionTabs
                                    questions={questions}
                                    onTabSelected={onTabSelected}
                                    onTabDeselected={onTabDeselected}
                                    completeTest={() => setShowCompleteConfirmation(true)}
                                    selectMostEffectiveAnswer={selectMostEffectiveAnswer}
                                    selectLessEffectiveAnswer={selectLessEffectiveAnswer}
                                    result={result}
                                />

                                <ConfirmDialogComponent
                                    open={showAbortConfirmation}
                                    title={"Are you sure you want to exit?"}
                                    content={"If you exit your progress will be lost"}
                                    cancelText={"Continue practice"}
                                    okText={"Exit"}
                                    cancelAction={() => setShowAbortConfirmation(false)}
                                    okAction={() => onAbortTest()}
                                />

                                <ConfirmDialogComponent
                                    open={showCompleteConfirmation}
                                    title={"Are you sure you want to complete this session?"}
                                    content={"Pressing complete will show your results."}
                                    cancelText={"Continue practice"}
                                    okText={"Complete"}
                                    cancelAction={() => setShowCompleteConfirmation(false)}
                                    okAction={() => onCompleteTest()}
                                />
                            </>
                        )}
                    </>
                )}
            </Dialog>
        </>
    );
};

declare global {
    interface Window {
        [key: string]: any;
        appSettings: any;
    }
}

export const APP_SETTINGS = {
    APIUrl: (window.appSettings && window.appSettings.apiUrl) || "",
};

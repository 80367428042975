import React from "react";
import { Tooltip, Button } from "@material-ui/core";

export const PracticeButton: React.FC<{
    tooltip?: string;
    disabled?: boolean;
    onClick: () => void;
}> = ({ tooltip, disabled, onClick, children }) => {
    return (
        <>
            <Tooltip title={tooltip ?? ""} enterDelay={500}>
                <span>
                    <Button disabled={disabled} onClick={onClick}>
                        {children}
                    </Button>
                </span>
            </Tooltip>
        </>
    );
};

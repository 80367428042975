import React from "react";
import { Typography, Button } from "@material-ui/core";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";

interface IOptionExplanation {
    explanation: string;
    explanationVideo: string;
    openVideo?: () => void;
}

export const OptionExplanation = ({ explanation, explanationVideo, openVideo }: IOptionExplanation) => {
    const createMarkup = (markup: string | undefined) => {
        return { __html: markup ? markup : "" };
    };

    const handleOpenVideo = () => openVideo?.(); //Check if not undefined

    let hasExplanationText = !!explanation;
    let hasExplanationVideo = !!explanationVideo;

    return (
        <>
            {hasExplanationText && (
                <>
                    <Typography variant="subtitle2">Explanation</Typography>
                    <Typography
                        variant="body2"
                        color="textPrimary"
                        dangerouslySetInnerHTML={createMarkup(explanation)}
                        gutterBottom
                    ></Typography>
                </>
            )}

            {hasExplanationVideo && (
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleOpenVideo}
                    startIcon={<PlayCircleOutlineIcon />}
                >
                    Watch the explanation video
                </Button>
            )}
        </>
    );
};

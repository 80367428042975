import React, { useState } from "react";
import { AppBar, Tabs, Tab, Grid, Button, Container, makeStyles, createStyles, Box } from "@material-ui/core";
import { IQuestionResult } from "../../../models/IQuestionResult";
import { QuestionContentTab } from "./QuestionContentTab";
import { SessionResultQuestionViewModel } from "../../../models/preplabModels";

interface ITestSessionButtonsProps {
    showPrevious: boolean;
    showNext: boolean;
    showCompleteTest: boolean;
    onPrevious: () => void;
    onNext: () => void;
    onCompleteTest: () => void;
}
export const TestSessionButtons = ({
    showPrevious,
    showNext,
    showCompleteTest,
    onNext,
    onPrevious,
    onCompleteTest,
}: ITestSessionButtonsProps) => {
    return (
        <>
            <Grid container direction="row" justify="space-between">
                <Grid item>
                    {showPrevious && (
                        <Button variant="contained" color="primary" onClick={onPrevious}>
                            Previous
                        </Button>
                    )}
                </Grid>
                <Grid item>
                    {showNext && (
                        <Button variant="contained" color="primary" onClick={onNext}>
                            Next
                        </Button>
                    )}
                    {showCompleteTest && (
                        <Button variant="contained" color="primary" onClick={onCompleteTest}>
                            Complete
                        </Button>
                    )}
                </Grid>
            </Grid>
        </>
    );
};

interface IQuestionTabsProps {
    questions: SessionResultQuestionViewModel[];
    result: { [questionId: string]: IQuestionResult };
    onTabDeselected: (tab: number, question: SessionResultQuestionViewModel) => void;
    onTabSelected: (tab: number, question: SessionResultQuestionViewModel) => void;
    completeTest: () => void;
    selectMostEffectiveAnswer: (optId: string, qId: string) => void;
    selectLessEffectiveAnswer: (optId: string, qId: string) => void;
}
const useStyles = makeStyles(() =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        tab: {
            minWidth: "64px",
        },
    }),
);

export const QuestionTabs = (props: IQuestionTabsProps) => {
    let {
        questions,
        selectMostEffectiveAnswer,
        selectLessEffectiveAnswer,
        result,
        onTabSelected,
        onTabDeselected,
        completeTest,
    } = props;

    const classes = useStyles();

    const [selectedTab, setSelectedTab] = useState(0);

    function handleChange(event: any, newTab: number) {
        changeTab(selectedTab, newTab);
    }

    function onNext() {
        if (selectedTab < questions.length - 1) {
            let newSelectedTab = Math.min(selectedTab + 1, questions.length - 1);
            changeTab(selectedTab, newSelectedTab);
        }
    }

    function onPrevious() {
        if (selectedTab > 0) {
            let newSelectedTab = Math.max(selectedTab - 1, 0);
            changeTab(selectedTab, newSelectedTab);
        }
    }

    const changeTab = (oldTab: number, newTab: number) => {
        let oldQuestion = questions[oldTab];
        onTabDeselected(oldTab, oldQuestion);

        let newq = questions[newTab];

        setSelectedTab(newTab);

        onTabSelected(newTab, newq);
    };

    const onCompleteTest = () => {
        completeTest();
    };

    return (
        <>
            <AppBar position="relative" color="default">
                <Tabs
                    value={selectedTab}
                    onChange={handleChange}
                    indicatorColor="secondary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="on"
                >
                    {questions &&
                        questions.map((question, index) => (
                            <Tab key={question.Id} value={index} label={index + 1} className={classes.tab} />
                        ))}
                </Tabs>
            </AppBar>
            <Container>
                {questions &&
                    questions.map((question, index) => (
                        <QuestionContentTab
                            key={index}
                            isActive={selectedTab === index}
                            question={question}
                            selectMostEffectiveAnswer={selectMostEffectiveAnswer}
                            selectLessEffectiveAnswer={selectLessEffectiveAnswer}
                            questionResult={result[question.Id]}
                        />
                    ))}

                <Box mt={2}>
                    <TestSessionButtons
                        showPrevious={selectedTab > 0}
                        showNext={selectedTab < questions.length - 1}
                        showCompleteTest={selectedTab >= questions.length - 1}
                        onPrevious={onPrevious}
                        onNext={onNext}
                        onCompleteTest={onCompleteTest}
                    />
                </Box>
            </Container>
        </>
    );
};

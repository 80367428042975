import React from "react";
import { Typography, Grid } from "@material-ui/core";

interface IErrorProps {
    error: any;
}
export const ErrorComponent = (props: IErrorProps) => {
    const { error } = props;
    console.error(error);
    return (
        <Grid container item>
            <Typography variant="h5" color="error" align="center">
                Oops, something went wrong. Please try again later.
            </Typography>
        </Grid>
    );
};

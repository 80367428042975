import React, { useState, useEffect, useContext } from "react";
import Auth0Lock from "auth0-lock";
import { TenantSettingsViewModel } from "../models/preplabModels";
import { Auth0UserProfile } from "auth0-js";
import * as ClientStorage from "./cookies";
import * as Sentry from "@sentry/react";

export interface IAuth0Context {
    isAuthenticated: boolean;
    idToken: string;
    profile: Auth0UserProfile;
    login: () => void;
    logout: () => void;
}

export const Auth0Context = React.createContext<IAuth0Context>({} as any);

export const useAuth0 = () => useContext(Auth0Context);

interface IAuth0ProviderProps {
    children: any;
    settings: TenantSettingsViewModel;
    [index: string]: any;
}

export const Auth0Provider = ({ children, settings }: IAuth0ProviderProps) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [auth0Lock, setAuth0Lock] = useState<Auth0LockStatic | null>(null);
    const [profile, setProfile] = useState<Auth0UserProfile>({} as any);
    const [idToken, setIdToken] = useState("");

    let options = {
        closable: false,
        mustAcceptTerms: true,
        theme: {
            logo: settings.Auth0Logo,
        },
        languageDictionary: {
            title: "",
            signUpTerms:
                'I agree to the <a href="' + settings.PolicyUrl + '" target="_blank">terms and privacy policy</a>',
        },
        auth: {
            redirect: false,
            params: {
                scope: "openid email",
            },
        },
    };

    useEffect(() => {
        if (settings.Auth0ClientId && settings.Auth0Domain) {
            let auth = new Auth0Lock(settings.Auth0ClientId, settings.Auth0Domain, options);
            setAuth0Lock(auth);
        }
    }, []);

    const login = () => {
        if (auth0Lock) auth0Lock.show();
    };

    useEffect(() => {
        if (auth0Lock) {
            listenOnAuthenticated(auth0Lock);

            if (ClientStorage.get<boolean>("auth0.is.authenticated") && auth0Lock) {
                auth0Lock.checkSession({}, (error, result) => {
                    if (!error && result) {
                        setIdToken(result.idToken);
                        updateUserProfile(result.accessToken);
                        setIsAuthenticated(true);
                    } else {
                        login();
                    }
                });
            } else {
                login();
            }
        }
    }, [auth0Lock]);

    const updateUserProfile = (token: string) => {
        if (auth0Lock) {
            auth0Lock.getUserInfo(token, (error, profile) => {
                if (error) {
                    console.error(error);
                    return;
                }

                setProfile(profile);

                if (process.env.NODE_ENV === "production") Sentry.setUser({ id: profile.user_id });
            });
        }
    };

    const listenOnAuthenticated = (auth0: Auth0LockStatic) => {
        auth0.on("authenticated", (result) => {
            auth0.getUserInfo(result.accessToken, (error, profile) => {
                if (error) {
                    console.error(error);
                    return;
                }

                ClientStorage.save("auth0.is.authenticated", true, { daysUntilExpire: 1 });

                setIdToken(result.idToken);
                setProfile(profile);
                setIsAuthenticated(true);
                auth0.hide();
            });
        });
    };

    const logout = () => {
        setIdToken("");
        setProfile({} as any);
        setIsAuthenticated(false);

        ClientStorage.remove("auth0.is.authenticated");
        if (auth0Lock) auth0Lock.logout({});
    };

    return (
        <Auth0Context.Provider
            value={{
                isAuthenticated,
                idToken,
                profile,
                login,
                logout,
            }}
        >
            {children}
        </Auth0Context.Provider>
    );
};

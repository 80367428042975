import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@material-ui/core";

interface IConfirmDialogProps {
    title: string;
    content: string;
    cancelText: string;
    okText: string;
    open: boolean;
    cancelAction: () => void;
    okAction: () => void;
}

export const ConfirmDialogComponent = (props: IConfirmDialogProps) => {
    const { title, content, cancelAction, okAction, cancelText, okText, open } = props;

    const closeDialog = (callback: () => void) => {
        callback();
    };

    return (
        <Dialog open={open} onClose={() => closeDialog(cancelAction)} aria-labelledby="responsive-dialog-title">
            <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>{content}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => cancelAction()} color="primary">
                    {cancelText}
                </Button>
                <Button onClick={() => okAction()} color="primary" autoFocus>
                    {okText}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

import React from "react";
import { Card, CardContent, Grid, Typography, Button, CardActions } from "@material-ui/core";
import { PracticeButton } from "./PracticeButton";
import { TestSessionType, UserPackageCategoryViewModel } from "../../models/preplabModels";

interface IPracticeCategoryComponentProps {
    category: UserPackageCategoryViewModel;
    selectItem: (mode: TestSessionType, category: UserPackageCategoryViewModel) => void;
    buyItem?: (category: UserPackageCategoryViewModel) => void;
}
export const PracticeCategoryComponent = ({ category, selectItem, buyItem }: IPracticeCategoryComponentProps) => {
    const handleBuyItem = (cat: UserPackageCategoryViewModel): void => {
        if (buyItem) {
            buyItem(cat);
        }
    };

    return (
        <>
            <Card>
                <CardContent style={{ background: category ? category.Color : "#000", color: "#fff" }}>
                    <Grid container justify="space-between" spacing={0}>
                        <Grid item xs={7}>
                            <Typography variant="h6" color="inherit">
                                {category.Name}
                            </Typography>
                            {category.AvaliableQuestions > 0 && (
                                <Typography variant="body2" color="inherit">
                                    {category.AvaliableQuestions} questions avaliable
                                </Typography>
                            )}
                        </Grid>
                        {category && (
                            <Grid item xs={3}>
                                <Typography variant="h6" color="inherit" align="right">
                                    {category.Percentile} %
                                </Typography>
                                <Typography variant="body2" color="inherit" align="right">
                                    Your Percentile
                                </Typography>
                            </Grid>
                        )}
                    </Grid>
                </CardContent>
                <CardActions classes={{ root: "category-action" }}>
                    <Grid container spacing={0}>
                        {category.AlreadyHave && (
                            <Grid item>
                                <PracticeButton
                                    tooltip="No time limit practice"
                                    onClick={() => selectItem(TestSessionType.Practice, category)}
                                >
                                    Practice
                                </PracticeButton>
                            </Grid>
                        )}

                        {category.AlreadyHave && (
                            <Grid item>
                                <PracticeButton
                                    tooltip={
                                        category.CanPracticeWeaknesses
                                            ? "Practice your weaknesses"
                                            : "This feature will be enabled once we have identified your weaknesses, continue practicing"
                                    }
                                    disabled={!category.CanPracticeWeaknesses}
                                    onClick={() => selectItem(TestSessionType.Weaknesses, category)}
                                >
                                    Weaknesses
                                </PracticeButton>
                            </Grid>
                        )}

                        {category.AlreadyHave && (
                            <Grid item>
                                <PracticeButton
                                    tooltip="Timed exam"
                                    onClick={() => selectItem(TestSessionType.Exam, category)}
                                >
                                    Exam
                                </PracticeButton>
                            </Grid>
                        )}
                        {!category.AlreadyHave && (
                            <Grid item>
                                <Button onClick={() => handleBuyItem(category)}>Get More</Button>
                            </Grid>
                        )}
                    </Grid>
                </CardActions>
            </Card>
        </>
    );
};

import { useFetchPostApiData } from "./useFetchPostApiData";
import { SaveUserSettingsCommand, UserSettingsViewModel } from "./../models/preplabModels";
import { useFetchApiData } from "./useFetchApiData";

export const useGetUserSettings = () => {
    return useFetchApiData<UserSettingsViewModel>("/api/UserSettings/GetUserSettings");
};

export const useSaveUserSettings = () => {
    return useFetchPostApiData<SaveUserSettingsCommand, any>("/api/UserSettings/SaveUserSettings");
};

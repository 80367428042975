import React from "react";
import {
    Card,
    CardMedia,
    CardContent,
    CardActions,
    Typography,
    Button,
    makeStyles,
    Theme,
    createStyles,
    CardActionArea,
    Divider,
} from "@material-ui/core";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import ShopOutlinedIcon from "@material-ui/icons/ShopOutlined";
import { LessonsLessonViewModel } from "../../models/preplabModels";

const useStyles: any = makeStyles((theme: Theme) =>
    createStyles({
        card: {
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
        },
    }),
);

interface ILessonProps {
    lesson: LessonsLessonViewModel;
    getAccess: () => void;
    goToDetails: (lessonId: string) => void;
}
export const LessonItemComponent = (props: ILessonProps) => {
    let { lesson, getAccess, goToDetails } = props;

    const classes = useStyles();

    const createMarkup = (markup: string | undefined) => {
        return { __html: markup ? markup : "" };
    };

    return (
        <>
            <Card className={classes.card} square={true}>
                <CardActionArea disabled={!lesson.HaveAccess} onClick={goToDetails.bind(null, lesson.Id ?? "")}>
                    <CardMedia image={lesson.Thumbnail} style={{ paddingTop: `56.25%`, objectFit: "cover" }} />
                    <Divider />
                    <CardContent>
                        <Typography
                            variant="caption"
                            dangerouslySetInnerHTML={createMarkup(lesson.Description)}
                        ></Typography>
                    </CardContent>
                </CardActionArea>
                <CardActions>
                    {!lesson.HaveAccess && (
                        <Button
                            fullWidth={true}
                            onClick={getAccess}
                            size="small"
                            color="secondary"
                            variant="text"
                            endIcon={<ShopOutlinedIcon />}
                        >
                            Get Access
                        </Button>
                    )}
                    {lesson.HaveAccess && (
                        <Button
                            fullWidth={true}
                            size="small"
                            onClick={goToDetails.bind(null, lesson.Id ?? "")}
                            color="primary"
                            variant="text"
                            endIcon={<PlayCircleOutlineIcon />}
                        >
                            Play
                        </Button>
                    )}
                </CardActions>
            </Card>
        </>
    );
};

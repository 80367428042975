import React, { useEffect } from "react";
import { Grid, Typography } from "@material-ui/core";
import { ProgressComponent } from "../../components/Shared/Progress/ProgressComponent";
import { PracticeCategorySettingsComponent } from "./PracticeCategorySettingsComponent";
import { ErrorComponent } from "../error/ErrorComponent";
import { useGetUserSettings, useSaveUserSettings } from "../../services/userSettings.service";
import { StoreActions, useGlobalStore, useGlobalStoreDispatch } from "../../storage/GlobalStoreContext";
import { FetchStatus } from "../../services/useFetchApiData";
import { SaveUserSettingsCommand, UserSettingsViewModel } from "../../models/preplabModels";
import { PostState } from "../../services/useFetchPostApiData";

export const SettingsContainer = () => {
    const [status, error, userSettings] = useGetUserSettings();
    const [response, saveSettings] = useSaveUserSettings();

    const store = useGlobalStore();
    const dispatch = useGlobalStoreDispatch();

    useEffect(() => {
        dispatch({ type: StoreActions.SetPageTitle, payload: "My Settings" });
    }, [dispatch]);

    const saveUserSettings = (settings: UserSettingsViewModel) => {
        const command: SaveUserSettingsCommand = {
            CategoriesSettings: settings.CategoriesSettings,
        };
        saveSettings(command);
    };

    return (
        <>
            <Grid container spacing={4} direction="column" justify="center">
                {status === FetchStatus.Loading ? (
                    <ProgressComponent text="Loading settings" />
                ) : status === FetchStatus.Error ? (
                    <ErrorComponent error={error} />
                ) : (
                    <>
                        {userSettings && (
                            <Grid item xs>
                                <Typography variant="h6" gutterBottom>
                                    Practice settings
                                </Typography>

                                <PracticeCategorySettingsComponent
                                    userSettings={userSettings}
                                    processing={response.status === PostState.Sending}
                                    saveUserSettings={saveUserSettings}
                                />
                            </Grid>
                        )}
                        <Grid item>
                            <Typography variant="caption" color="textSecondary">
                                App Version: {store.appVersion}
                            </Typography>
                        </Grid>
                    </>
                )}
            </Grid>
        </>
    );
};

import React from "react";
import { Grid, Card, CardContent, Typography, makeStyles, Theme, createStyles, CardMedia } from "@material-ui/core";
import { SingleOptionSelectionList } from "./SingleOptionSelectionList";
import { MostLeastOptionSelectionList } from "./MostLeastOptionSelectionList";
import ContentLoader from "react-content-loader";
import { IQuestionResult } from "../../../models/IQuestionResult";
import { SessionResultQuestionViewModel } from "../../../models/preplabModels";
import { useImage } from "../../../cache/useImage";

interface IQuestionProps {
    isActive: boolean;
    question: SessionResultQuestionViewModel;
    selectMostEffectiveAnswer: (optId: string, qId: string) => void;
    selectLessEffectiveAnswer: (optId: string, qId: string) => void;
    questionResult: IQuestionResult;
}

const useStyles: any = makeStyles((theme: Theme) =>
    createStyles({
        appBar: {
            position: "relative",
        },
        title: {
            marginLeft: theme.spacing(2),
            flex: 1,
        },
        grid: {
            marginTop: theme.spacing(2),
        },
        card: {},
        media: {
            padding: 5,
            height: "100%",
            width: "100%",
        },
    }),
);

const ImageSkeleton = () => (
    <ContentLoader
        speed={2}
        width={500}
        height={200}
        viewBox="0 0 500 200"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        title="Loading"
    >
        <rect x="0" y="0" rx="1" ry="1" width="100%" height="100%" />
    </ContentLoader>
);

export const QuestionContentTab = (props: IQuestionProps) => {
    const { question, questionResult, isActive } = props;
    const classes = useStyles();

    const { hasLoaded } = useImage(question.Image);

    function selectMostEffectiveAnswer(optionId: string) {
        props.selectMostEffectiveAnswer(optionId, question.Id);
    }

    function selectLessEffectiveAnswer(optionId: string) {
        props.selectLessEffectiveAnswer(optionId, question.Id);
    }

    let isSingleCorrect = question.IsSingleCorrect;
    let MostEffectiveAnswer = questionResult ? questionResult.MostEffectiveAnswer : "";
    let LessEffectiveAnswer = questionResult ? questionResult.LessEffectiveAnswer : "";

    return (
        <div hidden={!isActive}>
            <Grid container className={classes.grid} spacing={4}>
                <Grid item xs={12} sm={12} md={8} lg={7}>
                    <Card className={classes.card}>
                        <CardContent>
                            <Typography
                                variant="body1"
                                dangerouslySetInnerHTML={{ __html: `${question.Body}` }}
                            ></Typography>
                        </CardContent>
                        {question.Image && !hasLoaded && (
                            <CardContent>
                                <ImageSkeleton />
                            </CardContent>
                        )}

                        {question.Image && hasLoaded && (
                            <CardMedia
                                component="img"
                                src={question.Image}
                                title={question.Title}
                                className={classes.media}
                            ></CardMedia>
                        )}
                    </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={5}>
                    {isSingleCorrect && question.Options && (
                        <SingleOptionSelectionList
                            options={question.Options}
                            mostEffectiveAnswerId={MostEffectiveAnswer}
                            onSelect={selectMostEffectiveAnswer}
                            isExplanationMode={false}
                        />
                    )}

                    {!isSingleCorrect && question.Options && (
                        <MostLeastOptionSelectionList
                            options={question.Options}
                            mostEffectiveAnswerId={MostEffectiveAnswer}
                            leastEffectiveAnswerId={LessEffectiveAnswer}
                            onSelectMostEffective={selectMostEffectiveAnswer}
                            onSelectLeastEffective={selectLessEffectiveAnswer}
                            isExplanationMode={false}
                        />
                    )}
                </Grid>
            </Grid>
        </div>
    );
};

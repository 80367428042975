import React, { useState, useEffect } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    CircularProgress,
    DialogContentText,
    Typography,
    useTheme,
    useMediaQuery,
} from "@material-ui/core";
import { useSendFeedback } from "../../../services/feedback.service";
import Send from "@material-ui/icons/Send";
import { useTracker } from "../../../analytics/trackerContext";
import { PostState } from "../../../services/useFetchPostApiData";
import { SendQuestionFeedbackCommand } from "../../../models/preplabModels";

interface IQuestionFeedbackProps {
    questionName: string;
    questionId: string;
    open: boolean;
    onClose: () => void;
}

export const QuestionFeedbackDialog = ({ questionName, questionId, open, onClose }: IQuestionFeedbackProps) => {
    const [message, setMessage] = useState("");

    const track = useTracker();

    const [response, sendFeedback] = useSendFeedback();
    const onCloseCallback = React.useCallback(onClose, []);

    const progressStyle = {
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    };

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    useEffect(() => {
        if (response.status === PostState.Success && message !== "") {
            onCloseCallback();
        }
    }, [message, onCloseCallback, response.status]);

    const closeDialog = () => {
        onClose();
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMessage(event.target.value);
    };

    const sendFeedbackHandler = (event: any) => {
        event.preventDefault();

        if (message) {
            let questionFeedback: SendQuestionFeedbackCommand = {
                QuestionId: questionId,
                FeedbackMessage: message,
            };

            sendFeedback(questionFeedback);

            track.trackAction({
                eventAction: "Question Feedback Sent",
                eventCategory: "Feedback",
                eventLabel: questionName,
            });
        }
    };

    return (
        <>
            <Dialog open={open} fullWidth maxWidth="sm" onClose={closeDialog} fullScreen={fullScreen}>
                <DialogTitle>Question feedback</DialogTitle>
                <DialogContent>
                    <DialogContentText>Help us improve by sending us your feedback</DialogContentText>
                    <TextField
                        multiline
                        rows={5}
                        onChange={handleChange}
                        autoFocus
                        fullWidth
                        margin="dense"
                        label="Type your feedback"
                    />
                    {response.status === PostState.Error && (
                        <Typography variant="body2" color="error">
                            {response.error}
                        </Typography>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDialog} disabled={response.status === PostState.Sending}>
                        Close
                    </Button>

                    <Button
                        onClick={sendFeedbackHandler}
                        disabled={response.status === PostState.Sending}
                        endIcon={<Send color="action" />}
                    >
                        {response.status === PostState.Sending ? "Sending..." : "Send"}
                        {response.status === PostState.Sending && <CircularProgress size={25} style={progressStyle} />}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

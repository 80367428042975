import React from "react";
import { ProgressComponent } from "../../components/Shared/Progress/ProgressComponent";
import { Grid } from "@material-ui/core";
import { ErrorComponent } from "../error/ErrorComponent";
import { PracticeCategoryComponent } from "./PracticeCategoryComponent";
import { PracticeMultipleSelectorDialog, PracticeMultipleComponent } from "./PracticeMultipleComponent";

import { useEffect, useState } from "react";
import { PracticeTestSessionDialog } from "./TestSession/TestSessionDialog";
import { useHistory } from "react-router";
import { useGetUserPackages } from "../../services/shop.service";
import { StoreActions, useGlobalStoreDispatch } from "../../storage/GlobalStoreContext";

import { FetchStatus } from "../../services/useFetchApiData";
import { TestSessionType, UserPackageCategoryViewModel } from "../../models/preplabModels";

export const PracticeContainer = () => {
    const [openTestSessionDialog, setOpenTestSessionDialog] = useState(false);
    const [openComboSessionSelector, setOpenComboSessionSelector] = useState(false);
    const [selected, setSelected] = useState<{
        category: UserPackageCategoryViewModel[];
        mode: TestSessionType;
    }>({} as any);

    const [status, error, data] = useGetUserPackages();

    const dispatch = useGlobalStoreDispatch();

    let history = useHistory();

    useEffect(() => {
        dispatch({ type: StoreActions.SetPageTitle, payload: "Practice" });
    }, [dispatch]);

    function selectItem(mode: TestSessionType, category: UserPackageCategoryViewModel) {
        if (category) {
            setSelected({ category: [category], mode });
            setOpenTestSessionDialog(true);
        }
    }

    function openComboSelector(mode: TestSessionType) {
        setSelected((prevState) => ({ ...prevState, mode }));
        setOpenComboSessionSelector(true);
    }

    function selectCombo(categories: Array<UserPackageCategoryViewModel>) {
        setSelected((prevState) => ({ ...prevState, category: categories }));

        setOpenComboSessionSelector(false);
        setOpenTestSessionDialog(true);
    }

    function buyItem() {
        history.push("/shop");
    }

    function testSessionCompleted(sessionId: string) {
        setOpenTestSessionDialog(false);
        history.push("/practice/result/" + sessionId);
    }

    return (
        <>
            <Grid container>
                {status === FetchStatus.Loading ? (
                    <ProgressComponent text="Looking for areas to practice" />
                ) : status === FetchStatus.Error ? (
                    <ErrorComponent error={error} />
                ) : (
                    <Grid item container spacing={2}>
                        <Grid item xs={12}>
                            <PracticeMultipleComponent selectItem={openComboSelector} />
                        </Grid>
                        {data &&
                            data.Categories.map((category: UserPackageCategoryViewModel) => (
                                <Grid item xs={12} md={6} key={category.Id}>
                                    <PracticeCategoryComponent
                                        category={category}
                                        selectItem={selectItem}
                                        buyItem={buyItem}
                                    />
                                </Grid>
                            ))}
                    </Grid>
                )}
            </Grid>

            {data && (
                <PracticeMultipleSelectorDialog
                    categories={data.Categories}
                    open={openComboSessionSelector}
                    testsessiontype={selected.mode}
                    selectItems={selectCombo}
                    onClose={() => setOpenComboSessionSelector(false)}
                />
            )}

            {openTestSessionDialog && (
                <PracticeTestSessionDialog
                    category={selected.category}
                    mode={selected.mode}
                    open={openTestSessionDialog}
                    onClose={() => setOpenTestSessionDialog(false)}
                    onComplete={(id: string) => testSessionCompleted(id)}
                />
            )}
        </>
    );
};

import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

export default (props: SvgIconProps) => (
    <SvgIcon {...props} viewBox="0 0 3333 2924" shapeRendering="geometricPrecision" textRendering="geometricPrecision">
        <path
            d="M57 2204c-31 0-57-26-57-57s26-57 57-57h246V337H57c-31 0-57-26-57-57s26-57 57-57h1549V57c0-31 26-57 57-57s57 26 57 57v166h1549c31 0 57 26 57 57s-26 57-57 57h-243v1753h249c31 0 57 26 57 57s-26 57-57 57H1728v261c3 2 5 4 8 6l356 338c23 22 24 58 2 80-22 23-58 24-80 2l-286-271v246c0 31-26 57-57 57s-57-26-57-57v-257l-297 282c-23 22-59 21-80-2-22-23-21-59 2-80l356-338c6-5 12-10 19-12v-255H56zM1667 760c252 0 456 204 456 456s-204 456-456 456-456-204-456-456 204-456 456-456zm233 459l-364-237v485l364-249zM417 2090h2496V337H417v1753z"
            fillRule="nonzero"
        />
    </SvgIcon>
);

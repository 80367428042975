import React from "react";
import { Typography, Grid, LinearProgress } from "@material-ui/core";

interface IProgressProps {
    text: string;
}
export const ProgressComponent = (props: IProgressProps) => {
    const { text } = props;
    return (
        <Grid container justify="center" direction="column" alignItems="center" spacing={2}>
            <Grid item>
                {text && (
                    <Typography variant="subtitle1" color="inherit" align="center" gutterBottom>
                        {text}
                    </Typography>
                )}
                <LinearProgress />
            </Grid>
        </Grid>
    );
};

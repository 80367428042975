import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { App } from "./App";
import { GlobalStoreProvider } from "./storage/GlobalStoreContext";
import { BrowserRouter, Switch, Route } from "react-router-dom";

if (process.env.NODE_ENV === "production") {
    Sentry.init({
        dsn: "https://1f7319d9fcca43a98da95eb937e82214@o323433.ingest.sentry.io/1823820",
        release: "preplab-client@" + process.env.REACT_APP_VERSION,
        environment: process.env.NODE_ENV,
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: 0.05,
    });
}

const TenantRoot = () => (
    <BrowserRouter>
        <Switch>
            <Route path="/:tenantId?">
                <GlobalStoreProvider>
                    <App />
                </GlobalStoreProvider>
            </Route>
        </Switch>
    </BrowserRouter>
);

ReactDOM.render(<TenantRoot />, document.getElementById("root"));

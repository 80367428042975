import React from "react";
import { Card, CardHeader, CardContent, Grid, Box } from "@material-ui/core";
import { Bar } from "react-chartjs-2";
import { TestSessionHelper } from "../../common/testSessionHelper";
import { SessionResultCategoryViewModel } from "../../models/preplabModels";

interface IResultTimeScoreProps {
    testSessionResult: SessionResultCategoryViewModel[];
    gotoQuestion: Function;
}

//export const ResultTimeScoreComponent = (props: IResultTimeScoreProps) => {
export class ResultTimeScoreComponent extends React.Component<IResultTimeScoreProps> {
    render() {
        let { testSessionResult, gotoQuestion } = this.props;
        let timeLabels = [];
        let timeDatasetOverride = [
            {
                backgroundColor: new Array<string>(),
                borderWidth: 0,
            },
            {
                backgroundColor: new Array<string>(),
                borderWidth: 0,
            },
        ];
        var tempMyTime = new Array<number>();
        var tempOthersTime = new Array<number>();

        let maxQuestionTimeS = testSessionResult.reduce(
            (max, val) => (max > val.MaxQuestionTimeS ? max : val.MaxQuestionTimeS),
            testSessionResult[0].MaxQuestionTimeS,
        );

        let timeOptions = {
            responsive: true,
            maintainAspectRatio: false,
            barShowStroke: false,
            onClick: function (event?: MouseEvent, activeElements?: Array<{}>) {
                gotoQuestion(activeElements);
            },
            tooltips: {
                callbacks: {
                    label: function (tooltipItem: any, data: any) {
                        var value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                        return Math.round(value) + " seconds";
                    },
                    title: function (tooltipItem: any, data: any) {
                        return "";
                    },
                },
            },
            scales: {
                yAxes: [
                    {
                        ticks: {
                            beginAtZero: true,
                            max: maxQuestionTimeS * 1.5,
                            min: 0,
                            stepSize: maxQuestionTimeS,
                            callback: function (value: string) {
                                return value + "s";
                            },
                        },
                        scaleLabel: {
                            display: true,
                            labelString: "Time",
                        },
                        afterBuildTicks: function (humdaysChart: any) {
                            humdaysChart.ticks = [];
                            humdaysChart.ticks.push(0);
                            humdaysChart.ticks.push(maxQuestionTimeS);
                        },
                    },
                ],
                xAxes: [
                    {
                        gridLines: {
                            display: false,
                        },
                        scaleLabel: {
                            display: false,
                            labelString: "Other users correct/points",
                        },
                    },
                ],
            },
        };

        let questionsData = testSessionResult.flatMap((c) => c.Questions);

        for (var i = 0; i < questionsData.length; i++) {
            tempOthersTime.push(questionsData[i].AvgDuration);

            if (questionsData[i].TimeSpentMs) {
                tempMyTime.push((questionsData[i].TimeSpentMs as number) / 1000);
            } else {
                tempMyTime.push(0);
            }

            timeLabels.push([i + 1]);
            timeDatasetOverride[0].backgroundColor.push(
                !questionsData[i].IsAnswered
                    ? "#DDDCD8"
                    : TestSessionHelper.getQuestionColorByState(questionsData[i].State),
            );
            timeDatasetOverride[1].backgroundColor.push("#000000");
        }

        const barData = {
            labels: timeLabels,
            datasets: [
                {
                    data: tempMyTime,
                    backgroundColor: timeDatasetOverride[0].backgroundColor,
                },
                {
                    data: tempOthersTime,
                    backgroundColor: timeDatasetOverride[1].backgroundColor,
                },
            ],
            options: {
                onClick: gotoQuestion,
            },
        };
        const legendOpts = {
            display: false,
        };
        return (
            <div>
                <Card>
                    <CardHeader title="Time & Score Comparison" />
                    <CardContent>
                        <div className="chart-bar-box">
                            <Bar data={barData} height={200} redraw options={timeOptions} legend={legendOpts} />
                        </div>
                        <Grid container justify="center" spacing={2}>
                            <Grid item>
                                <Box component="span" pl={1} borderLeft={10} borderColor="#26A65B">
                                    Correct
                                </Box>
                            </Grid>
                            <Grid item>
                                <Box component="span" pl={1} borderLeft={10} borderColor="#C93756">
                                    Incorrect
                                </Box>
                            </Grid>
                            <Grid item>
                                <Box component="span" pl={1} borderLeft={10} borderColor="#F0E68C">
                                    Partial
                                </Box>
                            </Grid>
                            <Grid item>
                                <Box component="span" pl={1} borderLeft={10} borderColor="#DDDCD8">
                                    Unanswered
                                </Box>
                            </Grid>
                            <Grid item>
                                <Box component="span" pl={1} borderLeft={10} borderColor="#000000">
                                    Other users
                                </Box>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </div>
        );
    }
}

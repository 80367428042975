import React from "react";
import {
    Grid,
    Typography,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    makeStyles,
    createStyles,
    Theme,
} from "@material-ui/core";
import { ProgressComponent } from "../../components/Shared/Progress/ProgressComponent";
import { LessonItemComponent } from "./LessonItemComponent";
import { ErrorComponent } from "../error/ErrorComponent";
import { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { useGetLessons } from "../../services/lessons.service";
import { StoreActions, useGlobalStoreDispatch } from "../../storage/GlobalStoreContext";
import { FetchStatus } from "../../services/useFetchApiData";
import { LessonsCategoryViewModel, LessonsLessonViewModel } from "../../models/preplabModels";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    }),
);

export const LessonsContainer = () => {
    const classes = useStyles();
    const [categoryId, setCategoryId] = useState<string>("00000000-0000-0000-0000-000000000000");

    const history = useHistory();

    const dispatch = useGlobalStoreDispatch();
    const [status, error, data] = useGetLessons(categoryId);

    useEffect(() => {
        dispatch({ type: StoreActions.SetPageTitle, payload: "Lessons" });
    }, [dispatch]);

    function getAccess() {
        history.push("/shop");
    }
    function goToDetails(id: string) {
        history.push("/lessons/play/" + id);
    }
    function changeFilterCategory(event: any) {
        setCategoryId(event.target.value as string);
    }

    return (
        <>
            <Grid container direction="column" spacing={5}>
                {status === FetchStatus.Loading ? (
                    <ProgressComponent text="Looking for lessons" />
                ) : status === FetchStatus.Error ? (
                    <ErrorComponent error={error} />
                ) : (
                    <>
                        <Grid item>
                            {data?.Categories && data.Categories.length > 0 && (
                                <FormControl className={classes.formControl}>
                                    <InputLabel id="category-select-label">Category</InputLabel>
                                    <Select
                                        id="category-select"
                                        labelId="category-select-label"
                                        value={categoryId}
                                        onChange={changeFilterCategory}
                                    >
                                        <MenuItem value="00000000-0000-0000-0000-000000000000">All</MenuItem>
                                        {data.Categories.map((category: LessonsCategoryViewModel) => (
                                            <MenuItem key={category.Id} value={category.Id}>
                                                {category.Name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            )}
                        </Grid>
                        <Grid item>
                            <Grid container spacing={2}>
                                {data?.Lessons &&
                                    data.Lessons.map((lesson: LessonsLessonViewModel) => (
                                        <Grid item key={lesson.Id} xs={12} md={6} lg={3}>
                                            <LessonItemComponent
                                                lesson={lesson}
                                                getAccess={getAccess}
                                                goToDetails={goToDetails}
                                            />
                                        </Grid>
                                    ))}
                                {(!data?.Lessons || data.Lessons.length === 0) && (
                                    <Grid item xs>
                                        <Typography variant="subtitle2" color="textSecondary" align="center">
                                            No lessons are available yet, in mean time why not do couple of practice
                                            rounds.
                                        </Typography>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </>
                )}
            </Grid>
        </>
    );
};

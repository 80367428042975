import React from "react";
import { Card, CardContent, Grid, Typography, CardActionArea, CardHeader, Box, Divider } from "@material-ui/core";
import { TestSessionType, TimelineItemViewModel } from "../../models/preplabModels";

interface ITimelineItemProps {
    timeline: TimelineItemViewModel;
    showDetails: (categoryId: string) => void;
}

export const TimelineItemComponent = (props: ITimelineItemProps) => {
    const { timeline, showDetails } = props;
    const cardStyle = {
        maxWidth: 345,
    };

    const handleDetails = () => {
        showDetails(timeline.Id);
    };

    const getActivityTitle = (type: TestSessionType): string => {
        switch (type) {
            case TestSessionType.Practice:
                return "Practice session";
            case TestSessionType.Weaknesses:
                return "Weaknesses session";
            case TestSessionType.Exam:
                return "Exam session";

            default:
                return "";
        }
    };

    return (
        <Card style={cardStyle} square={true}>
            <CardActionArea onClick={handleDetails}>
                <CardHeader
                    title={<Typography>{getActivityTitle(timeline.ActivityType)}</Typography>}
                    subheader={<Typography variant="caption">{timeline.Created}</Typography>}
                />
                <CardContent>
                    <Grid container justify="space-between" direction="row">
                        <Grid item>
                            <Typography variant="h4">{timeline.TotalCorrectNormalized}%</Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="caption">Time spent</Typography>
                            <Typography variant="subtitle1" gutterBottom>
                                {Math.round(timeline.TotalTimeSpentMs / 1000)} s
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
                <Divider variant="fullWidth" />
                <CardContent>
                    <Grid container>
                        {timeline.TestSessionCategories.length > 0 &&
                            timeline.TestSessionCategories.map((tim, index) => (
                                <Grid key={index} item container direction="row" justify="space-between" spacing={2}>
                                    <Grid item>
                                        <Box color={tim.CategoryColor}>
                                            <Typography variant="subtitle2">{tim.CategoryName}</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item>{/* Intentionally Empty */}</Grid>
                                    <Grid item>
                                        <Typography variant="subtitle2">{tim.CorrectNormalized}%</Typography>
                                    </Grid>
                                </Grid>
                            ))}
                    </Grid>
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

import React from "react";
import { Card, CardContent, CardHeader } from "@material-ui/core";

import { Doughnut } from "react-chartjs-2";
import { TestSessionHelper } from "../../common/testSessionHelper";
import { SessionResultViewModel } from "../../models/preplabModels";

interface IResultAnswersProps {
    result: SessionResultViewModel;
}
export const ResultAnswersComponent = (props: IResultAnswersProps) => {
    let { result } = props;
    let percentCorrect = result.CorrectNormalized;
    let percentUnanswered = result.UnansweredNormalized;
    let percentageLabels = ["Correct", "Unanswered", "Incorrect"];
    let chartColors = ["#26A65B", "#DDDCD8", "#C93756"];
    let percentageData = [percentCorrect, percentUnanswered, 100 - percentCorrect - percentUnanswered];
    let chartOptions = {
        maintainAspectRatio: false,
        rotation: 1 * Math.PI,
        circumference: 1 * Math.PI,
        tooltips: {
            displayColors: false,
            callbacks: {
                label: (tooltipItem: any, data: any) => {
                    var value = data.datasets[0].data[tooltipItem.index];
                    return (
                        TestSessionHelper.getLabelByColor(data.datasets[0].hoverBackgroundColor[tooltipItem.index]) +
                        ": " +
                        value +
                        " %"
                    );
                },
            },
        },
    };
    const data = {
        labels: percentageLabels,
        datasets: [
            {
                hoverBackgroundColor: ["#26A65B", "#DDDCD8", "#C93756"],
                hoverBorderColor: ["#26A65B", "#DDDCD8", "#C93756"],
                hoverBorderWidth: 0,
                backgroundColor: chartColors,
                data: percentageData,
            },
        ],
    };
    const legendOpts = {
        display: false,
    };

    return (
        <div>
            <Card>
                <CardHeader title={"Total score " + percentCorrect + "%"}></CardHeader>

                <CardContent>
                    <div>
                        <Doughnut data={data} height={150} options={chartOptions} legend={legendOpts} />
                    </div>
                </CardContent>
            </Card>
        </div>
    );
};

import React from "react";
import {
    Typography,
    Button,
    makeStyles,
    Theme,
    createStyles,
    List,
    Divider,
    ListItem,
    ListItemIcon,
    Checkbox,
    ListItemText,
    ListItemSecondaryAction,
    ButtonGroup,
    Box,
} from "@material-ui/core";

import { green, red, yellow } from "@material-ui/core/colors";
import { OptionExplanation } from "./OptionExplanation";
import { SessionResultOptionViewModel } from "../../../models/preplabModels";

const useStyles: any = makeStyles((theme: Theme) =>
    createStyles({
        buttonDefault: {
            color: theme.palette.secondary.contrastText,
            backgroundColor: theme.palette.secondary.main,
        },
        buttonCorrectSelected: {
            color: theme.palette.getContrastText(green.A700),
            backgroundColor: green.A700,
        },
        buttonIncorrectSelected: {
            color: theme.palette.getContrastText(red.A700),
            backgroundColor: red.A700,
        },
        buttonPartialSelected: {
            color: theme.palette.getContrastText(yellow.A400),
            backgroundColor: yellow.A400,
        },
        buttonCorrectAnswer: {
            color: green.A700,
            borderColor: green.A700,
        },

        checked: {},
    }),
);

interface IMostLeastOptionItemProps {
    option: SessionResultOptionViewModel;
    meSelected: boolean;
    leSelected: boolean;
    isExplanationMode: boolean;
    onSelected: (mostEffective: boolean, optionId: string) => void;
    onPlayVideo?: (url: string) => void;
}

export const MostLeastOptionItem = ({
    option,
    isExplanationMode,
    meSelected,
    leSelected,
    onPlayVideo,
    onSelected,
}: IMostLeastOptionItemProps) => {
    var classes = useStyles();

    const handleSelected = (mostEffective: boolean, id: string) => {
        if (onSelected) {
            onSelected(mostEffective, id);
        }
    };

    const getButtonColor = (selected: boolean, effectiveState: number) => {
        if (!selected && effectiveState === 1) return classes.buttonCorrectAnswer;
        else if (selected && effectiveState === 0) return classes.buttonIncorrectSelected;
        else if (selected && effectiveState === 1) return classes.buttonCorrectSelected;
        else if (selected && effectiveState === 2) return classes.buttonIncorrectSelected;

        return selected ? classes.buttonDefault : "";
    };

    const handleOpenVideo = () => {
        if (onPlayVideo && option.ExplanationVideo) onPlayVideo(option.ExplanationVideo);
    };

    return (
        <>
            <ListItem alignItems="flex-start">
                <ListItemIcon>
                    <Checkbox
                        checked={meSelected || leSelected}
                        edge="start"
                        tabIndex={-1}
                        disableRipple
                        readOnly
                        classes={{ checked: classes.checked }}
                    />
                </ListItemIcon>
                <ListItemText>
                    <Typography>{option.Text}</Typography>
                    <Box mt={2}>
                        <ButtonGroup variant="outlined">
                            <Button
                                disableRipple={isExplanationMode}
                                disableFocusRipple={isExplanationMode}
                                className={
                                    isExplanationMode
                                        ? getButtonColor(option.IsSelectedMost, option.MostEffectiveState)
                                        : meSelected
                                        ? classes.buttonDefault
                                        : ""
                                }
                                onClick={() => handleSelected(true, option.Id)}
                            >
                                Most Effective
                            </Button>
                            <Button
                                disableRipple={isExplanationMode}
                                disableFocusRipple={isExplanationMode}
                                className={
                                    isExplanationMode
                                        ? getButtonColor(option.IsSelectedLess, option.LeastEffectiveState)
                                        : leSelected
                                        ? classes.buttonDefault
                                        : ""
                                }
                                onClick={() => handleSelected(false, option.Id)}
                            >
                                Least Effective
                            </Button>
                        </ButtonGroup>
                    </Box>
                    {isExplanationMode && (
                        <Box mt={2}>
                            <OptionExplanation
                                explanation={option.Explanation}
                                explanationVideo={option.ExplanationVideo}
                                openVideo={handleOpenVideo}
                            />
                        </Box>
                    )}
                </ListItemText>
                <ListItemSecondaryAction></ListItemSecondaryAction>
            </ListItem>
        </>
    );
};

interface IMostLeastOptionSelectionListProps {
    options: SessionResultOptionViewModel[];
    mostEffectiveAnswerId: string;
    leastEffectiveAnswerId: string;
    isExplanationMode: boolean;
    onSelectMostEffective: (oId: string) => void;
    onSelectLeastEffective: (oId: string) => void;
    onPlayVideo?: (url: string) => void;
}

export const MostLeastOptionSelectionList = ({
    options,
    mostEffectiveAnswerId,
    leastEffectiveAnswerId,
    isExplanationMode,
    onSelectMostEffective,
    onSelectLeastEffective,
    onPlayVideo,
}: IMostLeastOptionSelectionListProps) => {
    function handleSelection(mostEffective: boolean, oId: string) {
        if (mostEffective) {
            onSelectMostEffective(oId);
        } else {
            onSelectLeastEffective(oId);
        }
    }

    return (
        <>
            <List>
                {options.map((item, index) => (
                    <React.Fragment key={item.Id}>
                        <MostLeastOptionItem
                            option={item}
                            meSelected={mostEffectiveAnswerId === item.Id}
                            leSelected={leastEffectiveAnswerId === item.Id}
                            onSelected={handleSelection}
                            isExplanationMode={isExplanationMode}
                            onPlayVideo={onPlayVideo}
                        />
                        {options[index + 1] ? <Divider /> : ""}
                    </React.Fragment>
                ))}
            </List>
        </>
    );
};

import React, { useEffect } from "react";
import { Grid, Typography } from "@material-ui/core";
import { TimelineItemComponent } from "./TimelineItemComponent";
import { ProgressComponent } from "../../components/Shared/Progress/ProgressComponent";
import { ErrorComponent } from "../error/ErrorComponent";
import { useHistory } from "react-router";
import { useGetTimelines } from "../../services/timeline.service";
import { StoreActions, useGlobalStoreDispatch } from "../../storage/GlobalStoreContext";
import { FetchStatus } from "../../services/useFetchApiData";

export const TimelineContainer = () => {
    let history = useHistory();

    const [status, error, data] = useGetTimelines();
    const dispatch = useGlobalStoreDispatch();

    useEffect(() => {
        dispatch({ type: StoreActions.SetPageTitle, payload: "Timeline" });
    }, [dispatch]);

    function showDetails(id: string) {
        history.push(`/practice/result/${id}`);
    }

    return (
        <>
            <Grid container direction="column" alignContent="center" justify="center" spacing={2}>
                {status === FetchStatus.Loading ? (
                    <Grid item xs>
                        <ProgressComponent text="Building your timeline" />
                    </Grid>
                ) : status === FetchStatus.Error ? (
                    <ErrorComponent error={error} />
                ) : (
                    <>
                        {data.Items.length === 0 && (
                            <Grid item xs>
                                <Typography variant="subtitle2" color="textSecondary" align="center">
                                    No activities avaliable yet. Go ahead and do couple of practice rounds.
                                </Typography>
                            </Grid>
                        )}
                        {data.Items.length > 0 &&
                            data.Items.map((tim) => (
                                <Grid item key={tim.Id}>
                                    <TimelineItemComponent timeline={tim} showDetails={showDetails} />
                                </Grid>
                            ))}
                    </>
                )}
            </Grid>
        </>
    );
};

import React from "react";
import { Card, CardContent, Grid, CardActions, Typography, Button, useTheme } from "@material-ui/core";
import { useTracker } from "../../../analytics/trackerContext";
import { VideoPlayer } from "../../../components/VideoPlayer/VideoPlayerComponent";
import { LessonViewModel } from "../../../models/preplabModels";

interface ILessonVideoProps {
    lesson: LessonViewModel;
    getAccess: Function;
}
export const LessonVideoComponent = (props: ILessonVideoProps) => {
    let { lesson, getAccess } = props;

    let watched = {
        reached25: false,
        reached50: false,
        reached75: false,
    };

    const theme = useTheme();

    const cardStyle = {
        maxWidth: theme.breakpoints.width("md"),
    };

    const tracker = useTracker();

    const trackProgress = (played: number) => {
        if (played >= 0.25 && !watched.reached25) {
            tracker.trackAction({
                eventAction: "Played 25%",
                eventCategory: "Lesson",
                eventLabel: lesson.Title ?? "titlenotset",
            });

            watched = { reached25: true, reached50: false, reached75: false };
        } else if (played >= 0.5 && !watched.reached50) {
            tracker.trackAction({
                eventAction: "Played 50%",
                eventCategory: "Lesson",
                eventLabel: lesson.Title ?? "titlenotset",
            });

            watched = { reached25: true, reached50: true, reached75: false };
        } else if (played >= 0.75 && !watched.reached75) {
            tracker.trackAction({
                eventAction: "Played 75%",
                eventCategory: "Lesson",
                eventLabel: lesson.Title ?? "titlenotset",
            });
            watched = { reached25: true, reached50: true, reached75: true };
        }
    };

    return (
        <>
            <Card style={cardStyle}>
                <CardContent>
                    {lesson.HaveAccess && lesson.VideoContent && (
                        <VideoPlayer
                            videoUrl={lesson.VideoContent}
                            thumbnailUrl={lesson.Thumbnail}
                            onStart={() =>
                                tracker.trackAction({
                                    eventAction: "Play",
                                    eventCategory: "Lesson",
                                    eventLabel: lesson.Title ?? "titlenotset",
                                })
                            }
                            onProgress={(played) => {
                                trackProgress(played);
                            }}
                        />
                    )}
                    {lesson.HaveAccess && lesson.Thumbnail && !lesson.VideoContent && (
                        <img src={lesson.Thumbnail} alt={lesson.Name} />
                    )}
                    {!lesson.HaveAccess && (
                        <Grid container alignItems="center" style={{ backgroundImage: `url(${lesson.Thumbnail})` }}>
                            <Grid item>
                                <Typography variant="h6" color="inherit" align="left">
                                    {lesson.Title}
                                </Typography>
                                <Typography
                                    variant="subtitle1"
                                    color="inherit"
                                    align="left"
                                    dangerouslySetInnerHTML={{ __html: `${lesson.Description}` }}
                                ></Typography>
                            </Grid>
                        </Grid>
                    )}
                </CardContent>
                <CardActions classes={{ root: "category-action" }}>
                    {!lesson.HaveAccess && (
                        <Button
                            size="large"
                            fullWidth={true}
                            onClick={getAccess.bind(null)}
                            color="primary"
                            variant="contained"
                        >
                            Get Access to this video
                        </Button>
                    )}
                    {lesson.HaveAccess && (
                        <div>
                            <Typography variant="h6" color="inherit" align="left">
                                {lesson.Title}
                            </Typography>
                            <Typography
                                variant="subtitle1"
                                color="inherit"
                                align="left"
                                dangerouslySetInnerHTML={{ __html: `${lesson.Description}` }}
                            ></Typography>
                        </div>
                    )}
                </CardActions>
            </Card>
        </>
    );
};

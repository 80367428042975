import { LessonsViewModel, LessonViewModel } from "./../models/preplabModels";
import { useFetchApiData } from "./useFetchApiData";

export const useGetLesson = (id: string) => {
    var url = "/api/Lessons/GetLesson?lessonId=" + id;
    return useFetchApiData<LessonViewModel>(url);
};

export const useGetLessons = (categoryId: string) => {
    var url = "/api/Lessons/GetLessons";
    if (categoryId) {
        url += "?categoryId=" + categoryId;
    }

    return useFetchApiData<LessonsViewModel>(url);
};

import React, { useState, useEffect } from "react";
import { Grid, Button, Typography, CircularProgress, makeStyles, createStyles, Theme } from "@material-ui/core";
import { useCreateCheckoutSession } from "../../../services/shop.service";
import { ISelectedPackage } from "../../../models/ISelectedPackage";
import { green } from "@material-ui/core/colors";
import { useTracker } from "../../../analytics/trackerContext";

import PaymentIcon from "@material-ui/icons/Payment";
import { PostState } from "../../../services/useFetchPostApiData";
import { StripeCreateCheckoutSessionCommand } from "../../../models/preplabModels";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        buttonProgress: {
            color: green[500],
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: -12,
            marginLeft: -12,
        },
        purchaseButton: {
            color: theme.palette.secondary.contrastText,
            backgroundColor: green[500],
            borderColor: green[600],
            "&:hover": {
                borderColor: green[300],
                backgroundColor: green[800],
            },
        },
    }),
);

interface IStripeCheckoutComponentProps {
    stripePublicKey: string;
    selectedPackage: ISelectedPackage;
}

export const StripeCheckoutComponent = ({ stripePublicKey, selectedPackage }: IStripeCheckoutComponentProps) => {
    const [loading, setLoading] = useState(false);

    const classes = useStyles();

    const [response, createSession] = useCreateCheckoutSession();
    const tracking = useTracker();

    const redirectToCheckoutCallbak = React.useCallback(() => {
        const stripe = window.Stripe(stripePublicKey);
        stripe.redirectToCheckout({
            sessionId: response.data,
        });
    }, [response.data, stripePublicKey]);

    useEffect(() => {
        if (response.status === PostState.Success && response.data) {
            redirectToCheckoutCallbak();
        }
    }, [redirectToCheckoutCallbak, response.data, response.status]);

    if (stripePublicKey === "" || selectedPackage === null) {
        return <></>;
    }

    const handlePay = () => {
        setLoading(true);

        let command: StripeCreateCheckoutSessionCommand = {
            Amount: +selectedPackage.price,
            PackageId: selectedPackage.packageId,
            Duration: +selectedPackage.duration,
            Description: selectedPackage.description,
            Name: selectedPackage.name,
            SuccessUrl: `${window.location.origin}/shop/receipt`,
            CancelUrl: window.location.href,
        };

        createSession(command);

        tracking.trackAction({
            eventAction: "Purchase Started",
            eventCategory: "Shop",
            eventLabel: selectedPackage.name,
        });
    };
    return (
        <>
            <Grid>
                {response.status === PostState.Error && (
                    <Grid item xs>
                        <Typography color="error">{response.error}</Typography>
                    </Grid>
                )}
                <Button
                    fullWidth
                    size="large"
                    variant="contained"
                    color="primary"
                    disabled={loading}
                    onClick={handlePay}
                    className={classes.purchaseButton}
                    startIcon={<PaymentIcon />}
                >
                    Pay with credit card
                    {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                </Button>
            </Grid>
        </>
    );
};

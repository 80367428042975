import React, { useEffect, useState } from "react";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { CoreLayout } from "./pages/CoreLayout";
import { LoginContainer } from "./pages/login/LoginContainer";
import { CssBaseline, makeStyles, Grid, Typography, LinearProgress } from "@material-ui/core";
import { AuthRoute } from "./components/Auth/AuthRoute";
import { useFavicon, useTitle } from "react-use";
import { Auth0Provider } from "./auth/auth0Context";
import { useGlobalStore } from "./storage/GlobalStoreContext";
import { TrackerProvider } from "./analytics/trackerContext";
import { SettingsHelper } from "./common/settingsHelper";

const useStyles = makeStyles(() => ({
    root: {
        display: "flex",
    },
}));

export const App = () => {
    const classes = useStyles();

    const { loading, settings } = useGlobalStore();

    const [title, setTitle] = useState("App | Loading...");
    const [theme, setTheme] = useState(createMuiTheme());

    useFavicon(`${settings.ImageRootPath}/favicon.png`);
    useTitle(title);

    useEffect(() => {
        if (settings.AppName) {
            setTitle(`App | ${settings.AppName}`);
        }
    }, [settings.AppName]);

    useEffect(() => {
        if (!loading) {
            setTheme(
                createMuiTheme({
                    palette: {
                        primary: SettingsHelper.getThemePalette(settings.PrimaryPalette ?? ""),
                        secondary: SettingsHelper.getThemePalette(settings.SecondaryPalette ?? ""),
                    },
                }),
            );
        }
    }, [loading, settings.PrimaryPalette, settings.SecondaryPalette]);

    const onRedirectCallback = (appState: any) => {
        window.history.replaceState(
            {},
            document.title,
            appState && appState.targetUrl ? appState.targetUrl : window.location.pathname,
        );
    };

    return (
        <>
            <MuiThemeProvider theme={theme}>
                <div className={classes.root}>
                    <CssBaseline />
                    {loading ? (
                        <Grid container direction="column" alignItems="center" alignContent="space-around">
                            <Grid item>
                                <Typography variant="subtitle2">Loading, Please Wait...</Typography>
                                <LinearProgress />
                            </Grid>
                        </Grid>
                    ) : (
                        <Auth0Provider settings={settings} onRedirectCallback={onRedirectCallback}>
                            <BrowserRouter basename={settings.IsProduction ? "/" : settings.TenantId}>
                                <TrackerProvider>
                                    <Switch>
                                        <Route path="/login" component={LoginContainer} />
                                        <AuthRoute component={CoreLayout} />
                                    </Switch>
                                </TrackerProvider>
                            </BrowserRouter>
                        </Auth0Provider>
                    )}
                </div>
            </MuiThemeProvider>
        </>
    );
};

import React from "react";
import { Card, CardContent, CardHeader, Grid, Typography } from "@material-ui/core";
import { Doughnut } from "react-chartjs-2";
import { InsightsViewModel } from "../../models/preplabModels";

interface IOverviewProps {
    insight: InsightsViewModel;
}
export const OverviewComponent = (props: IOverviewProps) => {
    let { insight } = props;
    let pieChartColors = ["#26A65B", "#DDDCD8"];
    let pieChartdatasetOverride = {
        hoverBackgroundColor: ["#26A65B", "#DDDCD8"],
        hoverBorderColor: ["#26A65B", "#DDDCD8"],
        hoverBorderWidth: 0,
    };
    let chartsOptions = {
        tooltips: {
            displayColors: false,
            callbacks: {
                label: function (tooltipItem: any, data: any) {
                    var value = data.datasets[0].data[tooltipItem.index];
                    return value + " %";
                },
            },
        },
    };

    let avgCorrectChartData = {
        labels: ["", ""],
        datasets: [
            {
                borderWidth: pieChartdatasetOverride.hoverBorderWidth,
                hoverBackgroundColor: pieChartdatasetOverride.hoverBackgroundColor,
                hoverBorderColor: pieChartdatasetOverride.hoverBorderColor,
                backgroundColor: pieChartColors,
                data: [insight.AvgCorrect, 100 - insight.AvgCorrect],
            },
        ],
    };
    let percentilChartData = {
        labels: ["", ""],
        datasets: [
            {
                borderWidth: pieChartdatasetOverride.hoverBorderWidth,
                hoverBackgroundColor: pieChartdatasetOverride.hoverBackgroundColor,
                hoverBorderColor: pieChartdatasetOverride.hoverBorderColor,
                backgroundColor: pieChartColors,
                data: [insight.OverallPercentile, 100 - insight.OverallPercentile],
            },
        ],
    };
    const legendOpts = {
        display: false,
    };
    return (
        <>
            <Card>
                <CardHeader title="Overview" className="text-center" />
                <CardContent>
                    <Grid container spacing={4} className="grid-container">
                        <Grid item xs={6} className="bordered-item">
                            <Doughnut
                                data={percentilChartData}
                                options={chartsOptions}
                                legend={legendOpts}
                                height={150}
                            />
                            <Typography variant="h5" color="inherit" align="center">
                                {insight.OverallPercentile} %
                            </Typography>
                            <Typography variant="subtitle1" color="inherit" align="center">
                                Overall percentile
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Doughnut
                                data={avgCorrectChartData}
                                options={chartsOptions}
                                legend={legendOpts}
                                height={150}
                            />

                            <Typography variant="h5" color="inherit" align="center">
                                {insight.AvgCorrect} %
                            </Typography>
                            <Typography variant="subtitle1" color="inherit" align="center">
                                Avg correct answers
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </>
    );
};

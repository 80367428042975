import React from "react";
import {
    Dialog,
    Paper,
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    makeStyles,
    Theme,
    createStyles,
    Container,
    Grid,
    Divider,
} from "@material-ui/core";
import { ISelectedPackage } from "../../models/ISelectedPackage";
import { StripeCheckoutComponent } from "./Payment/StripeCheckoutComponent";
import CloseIcon from "@material-ui/icons/Close";
import { isNullOrUndefined } from "util";
import { ReceiptSummaryItem } from "./Receipt/ReceiptSummaryItem";
import { PayPalCheckoutComponent } from "./Payment/PayPalCheckoutComponent";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        appBar: {
            position: "relative",
        },
        title: {
            marginLeft: theme.spacing(2),
            flex: 1,
        },
    }),
);

interface ICheckoutDialogProps {
    selectedPackage: ISelectedPackage | null;
    stripePublicKey: string;
    paypalPublicKey: string;
    open: boolean;
    onClose: () => void;
}

export const CheckoutDialog = (props: ICheckoutDialogProps) => {
    const { open, selectedPackage, stripePublicKey, paypalPublicKey, onClose } = props;

    const classes = useStyles();

    if (isNullOrUndefined(selectedPackage)) {
        return <></>;
    }

    return (
        <>
            <Dialog fullScreen open={open}>
                <AppBar className={classes.appBar}>
                    <Toolbar variant="dense">
                        <IconButton edge="start" color="inherit" aria-label="close" onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Complete your purchase
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Container maxWidth="sm">
                    <Grid container spacing={4} direction="column" style={{ marginTop: 2 }}>
                        <Grid item xs={12}>
                            <Typography variant="h6">Checkout summary</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper square={true} elevation={1}>
                                <ReceiptSummaryItem selectedPackage={selectedPackage} />
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h6">Payment method</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container direction="column" spacing={5}>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle1" color="textSecondary" gutterBottom>
                                        Pay with VISA, MasterCard, iDEAL, Giropay and others
                                    </Typography>
                                    <StripeCheckoutComponent
                                        selectedPackage={selectedPackage}
                                        stripePublicKey={stripePublicKey}
                                    />
                                </Grid>

                                <Divider variant="middle" />
                                <Typography variant="subtitle2" color="textSecondary" align="center">
                                    or
                                </Typography>

                                <Grid item xs={12}>
                                    <Typography variant="subtitle1" color="textSecondary" gutterBottom>
                                        Payment via PayPal
                                    </Typography>
                                    <PayPalCheckoutComponent
                                        selectedPackage={selectedPackage}
                                        paypalPublicKey={paypalPublicKey}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Dialog>
        </>
    );
};

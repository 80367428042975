import React from "react";
import { Card, CardContent, CardHeader, Grid, CardActions, Typography, useTheme } from "@material-ui/core";
import { Line } from "react-chartjs-2";
import { InsightCategoryViewModel } from "../../models/preplabModels";

interface IInsightCategoryProps {
    category: InsightCategoryViewModel;
}
export const InsightCategoryComponent = (props: IInsightCategoryProps) => {
    let { category } = props;

    const theme = useTheme();

    let chartsOptions = {
        tooltips: {
            displayColors: false,
            callbacks: {
                label: function (tooltipItem: any, data: any) {
                    var value = data.datasets[0].data[tooltipItem.index];
                    return value + " %";
                },
            },
        },
        scales: {
            yAxes: [
                {
                    gridLines: {
                        display: category.Sessions.length > 0,
                        drawBorder: false,
                    },
                    ticks: {
                        display: false,
                        beginAtZero: true,
                        max: 100,
                        min: 0,
                        stepSize: 50,
                    },
                },
            ],
            xAxes: [
                {
                    gridLines: {
                        display: false,
                    },
                },
            ],
        },
        maintainAspectRatio: false,
        responsive: true,
    };

    let labels = new Array<string>();
    let numbers = new Array<number>();

    for (var k = 0; k < category.Sessions.length; k++) {
        labels[k] = "";
        numbers[k] = category.Sessions[k].Result;
    }

    let chartData = {
        labels: labels,
        datasets: [
            {
                borderColor: "#97BBCD",
                pointBackgroundColor: "#97BBCD",
                pointBorderColor: "#FFFFFF",
                lineTension: 0.2,
                data: numbers,
            },
        ],
    };
    const legendOpts = {
        display: false,
    };
    return (
        <>
            <Card>
                <CardHeader
                    title={category.Name}
                    style={{ color: theme.palette.getContrastText(category.Color), background: category.Color }}
                />
                <CardContent style={{ background: category.Color }}>
                    <div>
                        <Line data={chartData} options={chartsOptions} height={100} legend={legendOpts} />
                    </div>
                </CardContent>
                <CardActions>
                    <Grid container spacing={0}>
                        <Grid item xs={4}>
                            <Typography variant="h5" color="inherit" align="center">
                                {category.UniqueQuestions}
                            </Typography>
                            <Typography variant="subtitle1" color="inherit" align="center">
                                Questions seen
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="h5" color="inherit" align="center">
                                {category.AvgCorrect} %
                            </Typography>
                            <Typography variant="subtitle1" color="inherit" align="center">
                                Your average
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="h5" color="inherit" align="center">
                                {category.OtherUsers} %
                            </Typography>
                            <Typography variant="subtitle1" color="inherit" align="center">
                                Other users
                            </Typography>
                        </Grid>
                    </Grid>
                </CardActions>
            </Card>
        </>
    );
};

import React from "react";
import { Card, CardContent, CardHeader, Grid, Box, Typography } from "@material-ui/core";
import { SessionResultCategoryViewModel } from "../../models/preplabModels";

interface ICategoryResultsComponent {
    categories: Array<SessionResultCategoryViewModel>;
}

export const CategoryResultsComponent = ({ categories }: ICategoryResultsComponent) => {
    return (
        <>
            <Card>
                <CardHeader title="Session details"></CardHeader>
                <CardContent>
                    <Grid container>
                        <Grid item container direction="row" spacing={2}>
                            <Grid item xs={6}>
                                <Typography variant="subtitle1"></Typography>
                            </Grid>

                            <Grid item xs={3}>
                                <Typography variant="body2" align="right">
                                    Score
                                </Typography>
                            </Grid>

                            <Grid item xs={3}>
                                <Typography variant="body2" align="right">
                                    Time
                                </Typography>
                            </Grid>
                        </Grid>
                        {categories.length > 0 &&
                            categories.map((cat, index) => (
                                <Grid key={index} item container direction="row" spacing={2}>
                                    <Grid item xs={6}>
                                        <Box color={cat.CategoryColor}>
                                            <Typography variant="subtitle1">{cat.DisplayName}</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography variant="subtitle2" align="right">
                                            {cat.CorrectNormalized}%
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography variant="subtitle2" align="right">
                                            {cat.TotalTimeSpentS}s
                                        </Typography>
                                    </Grid>
                                </Grid>
                            ))}
                    </Grid>
                </CardContent>
            </Card>
        </>
    );
};

import React from "react";
import { OnBoardingDialog, OnBoardingSlide } from "./OnBoardingDialog";
import CreativeThinking from "../../assets/icons/CreativeThinking";
import TrainingVideo from "../../assets/icons/TrainingVideo";
import GraduationCap from "../../assets/icons/GraduationCap";
import TranslateIcon from "@material-ui/icons/Translate";

export const WelcomeDialog = () => {
    return (
        <OnBoardingDialog settingsKey="welcomedialog">
            <OnBoardingSlide
                svgIcon={<TranslateIcon color="primary" />}
                title="Choose your language"
                text="In the settings page you can choose which language you prefer to practice in, and how many questions to include per test"
            />

            <OnBoardingSlide
                svgIcon={<TrainingVideo color="primary" />}
                title="Watch videos lessons"
                text="Our educational video lessons are designed by experts in their respective field. Do not miss out on our free videos"
            />

            <OnBoardingSlide
                svgIcon={<GraduationCap color="primary" />}
                title="Build your own tests"
                text="Build a complete timed or untimed tests and choose the categories you want to include in your tests"
            />

            <OnBoardingSlide
                svgIcon={<CreativeThinking color="primary" />}
                title="Focus on your weaknesses"
                text="Train smarter by only focusing on your weaknesses, repeat questions you have gotten wrong or spent too much time on."
            />
        </OnBoardingDialog>
    );
};

import React, { useState, useEffect } from "react";
import { Card, CardContent, CardHeader, Box } from "@material-ui/core";
import { ResponsiveRadar } from "@nivo/radar";
import { InsightsViewModel } from "../../models/preplabModels";

interface IRadarRankingProps {
    insight: InsightsViewModel;
}

export const RadarRankingComponent = ({ insight }: IRadarRankingProps) => {
    const [data, setData] = useState<{ category: string; percentile: number }[]>([]);

    useEffect(() => {
        let temp: { category: string; percentile: number }[] = [];

        insight.Categories?.forEach((cat) => {
            temp.push({ category: cat.Name, percentile: cat.Percentile });
        });

        setData(temp);
    }, [insight]);

    return (
        <>
            <Card>
                <CardHeader title="Your percentile overview" />
                <CardContent>
                    <Box height={240}>
                        <ResponsiveRadar
                            colors={{ scheme: "accent" }}
                            keys={["percentile"]}
                            indexBy="category"
                            maxValue={100}
                            curve="linearClosed"
                            borderWidth={1}
                            gridShape="linear"
                            gridLevels={5}
                            enableDots={true}
                            dotSize={5}
                            enableDotLabel={false}
                            dotLabel={(d) => `${d.value}%`}
                            dotBorderWidth={1}
                            margin={{ top: 25, right: 10, bottom: 25, left: 10 }}
                            isInteractive={true}
                            data={data}
                            legends={[]}
                            tooltipFormat={(value) => `${value}%`}
                        ></ResponsiveRadar>
                    </Box>
                </CardContent>
            </Card>
        </>
    );
};

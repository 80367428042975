import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import { Hidden, createStyles, makeStyles, Theme, Grid } from "@material-ui/core";
import { drawerWidth } from "../SideBar/SideBarComponent";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        appBar: {
            zIndex: theme.zIndex.drawer + 1,
            margin: 0,
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        logo: {
            width: drawerWidth,
        },
        title: {
            flex: 1,
        },
    }),
);

interface IAppBarProps {
    logoPath: string;
    toggleMobileSideBar: Function;
    pageTitle: string;
}

export const AppBarComponent = ({ logoPath, toggleMobileSideBar, pageTitle }: IAppBarProps) => {
    const classes = useStyles();

    return (
        <>
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar variant="dense">
                    <Hidden smUp>
                        <IconButton
                            edge="start"
                            color="inherit"
                            className={classes.menuButton}
                            aria-label="Menu"
                            onClick={toggleMobileSideBar(true)}
                        >
                            <MenuIcon />
                        </IconButton>
                    </Hidden>
                    <Hidden xsDown>
                        <Grid className={classes.logo}>
                            <img height="32" alt="logo" src={logoPath} />
                        </Grid>
                    </Hidden>
                    <Typography className={classes.title} variant="h6" color="inherit" align="center">
                        {pageTitle}
                    </Typography>
                </Toolbar>
            </AppBar>
        </>
    );
};

import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import { ISelectedPackage } from "../../models/ISelectedPackage";
import { ProgressComponent } from "../../components/Shared/Progress/ProgressComponent";
import { ErrorComponent } from "../error/ErrorComponent";
import { ShopPackageComponent } from "./ShopPackageComponent";
import { useEffect } from "react";
import { CheckoutDialog } from "./CheckoutDialog";
import { useHistory } from "react-router";
import { useGetShop } from "../../services/shop.service";
import { StoreActions, useGlobalStore, useGlobalStoreDispatch } from "../../storage/GlobalStoreContext";
import { FetchStatus } from "../../services/useFetchApiData";
import { PackageViewModel, SubscriptionViewModel } from "../../models/preplabModels";

interface IShopProps {}

export const ShopContainer = () => {
    const [selectedPackage, setSelectedPackage] = useState<ISelectedPackage | null>(null);
    const [openCheckoutDialog, setOpenCheckoutDialog] = useState(false);

    const [status, error, data] = useGetShop();

    const { settings } = useGlobalStore();
    const dispatch = useGlobalStoreDispatch();

    let history = useHistory();

    useEffect(() => {
        dispatch({ type: StoreActions.SetPageTitle, payload: "Shop" });
    }, [dispatch]);

    useEffect(() => {
        if (selectedPackage) {
            setOpenCheckoutDialog(true);
        }
    }, [selectedPackage]);

    const closeDialog = () => {
        setSelectedPackage(null);
        setOpenCheckoutDialog(false);
    };

    const goPractice = () => {
        history.push("/practice");
    };

    const createCharge = (pack: PackageViewModel, subs: SubscriptionViewModel) => {
        let selectedPackage: ISelectedPackage = {
            duration: subs.Duration,
            name: pack.Name,
            description: subs.Description,
            price: subs.Price,
            currency: subs.Currency,
            packageId: pack.PackageId,
        };
        setSelectedPackage(selectedPackage);
    };

    return (
        <>
            <Grid container spacing={0}>
                {status === FetchStatus.Loading ? (
                    <ProgressComponent text="Lowering prices" />
                ) : status === FetchStatus.Error ? (
                    <ErrorComponent error={error} />
                ) : (
                    <>
                        <Grid container spacing={2}>
                            {data &&
                                data.Packages.map((shopPackage) => (
                                    <Grid item key={shopPackage.PackageId} xs={12} md={6} lg={4}>
                                        <ShopPackageComponent
                                            goPractice={goPractice}
                                            shopPackage={shopPackage}
                                            createCharge={createCharge}
                                        />
                                    </Grid>
                                ))}
                        </Grid>
                        {data && (
                            <CheckoutDialog
                                open={openCheckoutDialog}
                                stripePublicKey={settings.StripePublicKey ?? ""}
                                paypalPublicKey={settings.PayPalPublicKey ?? ""}
                                selectedPackage={selectedPackage}
                                onClose={closeDialog}
                            />
                        )}
                    </>
                )}
            </Grid>
        </>
    );
};

import { CloseTestSessionCommand, StartTestSessionCommand, StartTestSessionViewModel } from "./../models/preplabModels";
import { useFetchPostApiData } from "./useFetchPostApiData";
import { useFetchApiData } from "./useFetchApiData";
import { SessionResultViewModel } from "../models/preplabModels";

export const useGetTestSessionData = (sessionId: string) => {
    return useFetchApiData<SessionResultViewModel>(`/api/TestSession/GetSessionData?sessionId=${sessionId}`);
};

export const useStartTestSession = () => {
    return useFetchPostApiData<StartTestSessionCommand, StartTestSessionViewModel>("/api/TestSession/StartTestSession");
};

export const useCloseTestSession = () => {
    return useFetchPostApiData<CloseTestSessionCommand, null>("/api/TestSession/CloseTestSession");
};

import React, { useEffect } from "react";
import {
    Card,
    CardContent,
    Typography,
    Button,
    CardActions,
    CardHeader,
    Divider,
    Chip,
    Grid,
    makeStyles,
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { useState } from "react";
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";
import ArrowForwardOutlinedIcon from "@material-ui/icons/ArrowForwardOutlined";
import { PackageViewModel, SubscriptionViewModel } from "../../models/preplabModels";

const useStyles = makeStyles((theme) => ({
    card: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    },
    cardContent: {
        height: "100%",
    },
    chip: {},

    chipSelected: {
        color: theme.palette.secondary.contrastText,
        backgroundColor: theme.palette.primary.light,
    },

    purchaseButton: {
        color: theme.palette.secondary.contrastText,
        backgroundColor: green[400],
        borderColor: green[500],
        "&:hover": {
            borderColor: green[300],
            backgroundColor: green[800],
        },
    },
}));

interface IShopPackageProps {
    shopPackage: PackageViewModel;
    goPractice: () => void;
    createCharge: (pack: PackageViewModel, subs: SubscriptionViewModel) => void;
}
export const ShopPackageComponent = (props: IShopPackageProps) => {
    const { shopPackage, createCharge, goPractice } = props;
    const classes = useStyles();

    const [subscription, setSubscription] = useState<SubscriptionViewModel>({} as SubscriptionViewModel);

    useEffect(() => {
        if (shopPackage.Subscriptions && shopPackage.Subscriptions.length > 0) {
            setSubscription(shopPackage.Subscriptions[0]);
        }
    }, [shopPackage]);

    const handleDurationSelection = (sub: SubscriptionViewModel) => {
        setSubscription(sub);
    };

    const handlePay = (sub: SubscriptionViewModel) => {
        createCharge(shopPackage, sub);
    };

    return (
        <Card className={classes.card} square={true}>
            <CardHeader title={shopPackage.Name}></CardHeader>
            <CardContent className={classes.cardContent}>
                <Typography
                    variant="body2"
                    dangerouslySetInnerHTML={{ __html: `${shopPackage.Description}` }}
                ></Typography>
            </CardContent>
            {shopPackage.AlreadyHave && (
                <>
                    <CardContent>
                        <Grid container spacing={2} direction="column">
                            <Grid item>
                                <Divider />
                            </Grid>
                            <Grid item>
                                <Typography variant="subtitle2">Expiry date</Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="body1">
                                    {shopPackage.Left ? <span>{shopPackage.Left}</span> : "Unlimited"}
                                </Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                    <CardActions>
                        <Button
                            color="primary"
                            variant="text"
                            fullWidth={true}
                            onClick={goPractice}
                            endIcon={<ArrowForwardOutlinedIcon />}
                        >
                            Go practice
                        </Button>
                    </CardActions>
                </>
            )}
            {!shopPackage.AlreadyHave && (
                <>
                    <CardContent>
                        <Grid container spacing={2} direction="column">
                            <Grid item>
                                <Divider />
                            </Grid>
                            <Grid item>
                                <Typography variant="subtitle2">Select duration</Typography>
                            </Grid>
                            <Grid item>
                                <Grid container spacing={1}>
                                    {shopPackage.Subscriptions &&
                                        shopPackage.Subscriptions.map((sub, index) => (
                                            <Grid key={index} item>
                                                <Chip
                                                    clickable={false}
                                                    className={
                                                        subscription === sub ? classes.chipSelected : classes.chip
                                                    }
                                                    variant="outlined"
                                                    label={sub.Description}
                                                    onClick={() => handleDurationSelection(sub)}
                                                />
                                            </Grid>
                                        ))}
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                    <CardActions>
                        <Button
                            fullWidth={true}
                            variant="outlined"
                            color="secondary"
                            className={classes.purchaseButton}
                            onClick={() => handlePay(subscription)}
                            endIcon={<ShoppingCartOutlinedIcon />}
                        >
                            Checkout total {subscription.Price} {subscription.Currency}
                        </Button>
                    </CardActions>
                </>
            )}
        </Card>
    );
};

import React from "react";
import { Divider, Drawer, Hidden, MenuList, makeStyles, Grid, Theme, createStyles } from "@material-ui/core";
import { SideBarMenuItem } from "./SideBarMenuItem";
import { useAuth0 } from "../../auth/auth0Context";

export const drawerWidth: number = 240;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
            whiteSpace: "nowrap",
            border: "none",
        },
        drawerPaperMobile: {
            backgroundColor: "#fafafaff",
            width: drawerWidth,
        },
        drawerPaper: {
            backgroundColor: "#00000000",
            width: drawerWidth,
        },
        logo: {
            objectFit: "cover",
            height: "32px",
            margin: "2px",
        },
        toolbar: {
            ...theme.mixins.toolbar,
        },
    }),
);

interface ISideBarProps {
    logoPath: string;
    mobileSideBarOpen: boolean;
    toggleMobileSideBar: Function;
}
export const SideBarComponent = (props: ISideBarProps) => {
    const classes = useStyles();

    const { mobileSideBarOpen, toggleMobileSideBar, logoPath } = props;

    const { logout } = useAuth0();

    function onLogOut() {
        logout();
    }

    const MenuItems = (
        <>
            <Hidden smUp>
                <Grid>
                    <img alt="logo" src={logoPath} className={classes.logo} />
                </Grid>
                <Divider />
            </Hidden>

            <MenuList>
                <SideBarMenuItem title="Timeline" icon="history" to="/timeline" />
                <SideBarMenuItem title="Insights" icon="timeline" to="/insights" />
                <Divider />
                <SideBarMenuItem title="Lessons" icon="video_library" to="/lessons" />
                <SideBarMenuItem title="Practice" icon="ballot" to="/practice" />
                <Divider />
                <SideBarMenuItem title="Shop" icon="shopping_cart" to="/shop" />
                <Divider />
                <SideBarMenuItem title="Settings" icon="person" to="/settings" />
                <SideBarMenuItem title="Log Out" icon="exit_to_app" to="" onClick={onLogOut} />
            </MenuList>
        </>
    );

    return (
        <>
            <Hidden smUp>
                <Drawer
                    variant="temporary"
                    anchor="left"
                    open={mobileSideBarOpen}
                    className={classes.drawer}
                    classes={{
                        paper: classes.drawerPaperMobile,
                    }}
                    onClose={toggleMobileSideBar(false)}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                >
                    {MenuItems}
                </Drawer>
            </Hidden>
            <Hidden xsDown>
                <Drawer
                    variant="permanent"
                    className={classes.drawer}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    open
                >
                    <div className={classes.toolbar} />
                    {MenuItems}
                </Drawer>
            </Hidden>
        </>
    );
};

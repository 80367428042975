import React, { useEffect, useContext, useReducer } from "react";
import { useGetSettings } from "../services/settings.service";
import { useLocalStorage } from "react-use";
import { FetchStatus } from "../services/useFetchApiData";
import { TenantSettingsViewModel } from "../models/preplabModels";
import { useParams } from "react-router-dom";

interface IPersistentAppState {
    [key: string]: boolean | number | string | undefined;
}

export const GlobalStoreContext = React.createContext<IGlobalStore>({} as any);
export const GlobalStoreDispatchContext = React.createContext<React.Dispatch<GlobalStoreAction>>({} as any);

export const useGlobalStore = () => useContext(GlobalStoreContext);
export const useGlobalStoreDispatch = () => useContext(GlobalStoreDispatchContext);

export enum StoreActions {
    SetPageTitle,
    SetAppSettings,
    SetSettings,
    SetTenantId,
}

type GlobalStoreAction =
    | { type: StoreActions.SetPageTitle; payload: string }
    | { type: StoreActions.SetAppSettings; payload: IPersistentAppState }
    | { type: StoreActions.SetSettings; payload: TenantSettingsViewModel };

export interface IGlobalStore {
    loading: boolean;
    settings: TenantSettingsViewModel;
    tenantId?: string;
    appVersion?: string;
    pageTitle: string;
    appSettings: IPersistentAppState;
}

interface IGlobalStoreProviderProps {
    children: any;
    [index: string]: any;
}

export const GlobalStoreProvider = ({ children }: IGlobalStoreProviderProps) => {
    const stateReducer = (state: IGlobalStore, action: GlobalStoreAction): IGlobalStore => {
        switch (action.type) {
            case StoreActions.SetPageTitle:
                return { ...state, pageTitle: action.payload };
            case StoreActions.SetAppSettings:
                return { ...state, appSettings: action.payload };
            case StoreActions.SetSettings:
                return { ...state, loading: false, settings: action.payload, tenantId: action.payload?.TenantId };
            default:
                return state;
        }
    };

    var { tenantId } = useParams<{ tenantId: string | undefined }>();

    const [status, , data] = useGetSettings(tenantId);

    const [localSettings, setSettings] = useLocalStorage<IPersistentAppState>(
        "app.settings",
        {} as IPersistentAppState,
    );

    const [state, dispatch] = useReducer<React.Reducer<IGlobalStore, GlobalStoreAction>>(stateReducer, {
        loading: true,
        pageTitle: "",
        appVersion: process.env.REACT_APP_VERSION,
        appSettings: localSettings ?? {},
        settings: data,
    });

    useEffect(() => {
        if (status === FetchStatus.Success && data) {
            dispatch({ type: StoreActions.SetSettings, payload: data });
        }
    }, [status, data]);

    useEffect(() => {
        setSettings(state.appSettings);
    }, [setSettings, state.appSettings]);

    return (
        <GlobalStoreContext.Provider value={state}>
            <GlobalStoreDispatchContext.Provider value={dispatch}>{children}</GlobalStoreDispatchContext.Provider>
        </GlobalStoreContext.Provider>
    );
};

import React, { useEffect } from "react";
import { Grid, Typography } from "@material-ui/core";
import { ProgressComponent } from "../../../components/Shared/Progress/ProgressComponent";
import { ErrorComponent } from "../../error/ErrorComponent";
import { LessonVideoComponent } from "./LessonVideoComponent";
import { useHistory, useParams } from "react-router-dom";
import { useGetLesson } from "../../../services/lessons.service";
import { StoreActions, useGlobalStoreDispatch } from "../../../storage/GlobalStoreContext";
import { FetchStatus } from "../../../services/useFetchApiData";

interface ILessonProps {}

export const LessonPlayContainer = () => {
    let history = useHistory();
    let { id } = useParams<{ id: string }>();

    let param = id ? id : "";
    const [status, error, data] = useGetLesson(param);

    const dispatch = useGlobalStoreDispatch();

    useEffect(() => {
        dispatch({ type: StoreActions.SetPageTitle, payload: "Play" });
    }, [dispatch]);

    const getAccess = () => {
        history.push("/shop");
    };

    return (
        <>
            <Grid container direction="row" spacing={0} alignContent="center" justify="center">
                {status === FetchStatus.Loading ? (
                    <ProgressComponent text="Loading lesson" />
                ) : status === FetchStatus.Error ? (
                    <ErrorComponent error={error} />
                ) : (
                    <Grid item>
                        <Grid container spacing={2}>
                            {data && (
                                <Grid item>
                                    <LessonVideoComponent lesson={data} getAccess={getAccess} />
                                </Grid>
                            )}
                            {!data && (
                                <Grid item>
                                    <Typography variant="h5" color="inherit" align="left">
                                        No lesson found
                                    </Typography>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </>
    );
};

import React from "react";
import ReactPlayer from "react-player";
import { Grid } from "@material-ui/core";

interface IVideoPlayerProps {
    videoUrl: string;
    thumbnailUrl?: string;
    onStart?: () => void;
    onProgress?: (played: number) => void;
}

export const VideoPlayer = ({ videoUrl, thumbnailUrl, onStart, onProgress }: IVideoPlayerProps) => {
    const handleOnStart = () => {
        if (onStart) onStart();
    };

    const handleOnProgress = (played: number) => {
        if (onProgress) onProgress(played);
    };

    return (
        <Grid container direction="row" justify="center" alignItems="center">
            <Grid item>
                <ReactPlayer
                    url={videoUrl}
                    controls={true}
                    config={{
                        file: {
                            attributes: {
                                controlsList: "nodownload",
                                poster: thumbnailUrl,
                                onContextMenu: (e: any) => e.preventDefault(),
                            },
                        },
                    }}
                    onStart={handleOnStart}
                    onProgress={(state) => handleOnProgress(state.played)}
                />
            </Grid>
        </Grid>
    );
};

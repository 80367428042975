import indigo from "@material-ui/core/colors/indigo";
import pink from "@material-ui/core/colors/pink";
import blue from "@material-ui/core/colors/blue";
import blueGrey from "@material-ui/core/colors/blueGrey";

export class SettingsHelper {
    public static getThemePalette(name: string): any {
        switch (name.toLowerCase()) {
            case "indigo":
                return indigo;
            case "pink":
                return pink;
            case "blue":
                return blue;
            case "blue-grey":
            case "bluegrey":
                return blueGrey;
            default:
                return indigo;
        }
    }
}

import React, { useContext, useEffect } from "react";
import TagManager from "react-gtm-module";
import { useAuth0 } from "../auth/auth0Context";
import { useGlobalStore } from "../storage/GlobalStoreContext";
import { useLocation } from "react-router";

type userActions =
    | { eventAction: "Signed In"; eventCategory: "User" }
    | { eventAction: "Signed Up"; eventCategory: "User" };

type feedbackActions = { eventAction: "Question Feedback Sent"; eventCategory: "Feedback"; eventLabel: string };

type lessonsActions =
    | { eventAction: "Play"; eventCategory: "Lesson"; eventLabel: string }
    | { eventAction: "Played 25%"; eventCategory: "Lesson"; eventLabel: string }
    | { eventAction: "Played 50%"; eventCategory: "Lesson"; eventLabel: string }
    | { eventAction: "Played 75%"; eventCategory: "Lesson"; eventLabel: string };

type practiceActions =
    | { eventAction: "Exam Practice Started"; eventCategory: "Practice"; eventLabel: string }
    | { eventAction: "Weakness Practice Started"; eventCategory: "Practice"; eventLabel: string }
    | { eventAction: "Regular Practice Started"; eventCategory: "Practice"; eventLabel: string }
    | { eventAction: "Practice Started"; eventCategory: "Practice"; eventLabel: string; eventValue: string }
    | { eventAction: "Customized Practice Started"; eventCategory: "Practice"; eventLabel: string; eventValue: string }
    | { eventAction: "Practice Completed"; eventCategory: "Practice"; eventLabel: string };

type shopActions =
    | { eventAction: "Purchase Started"; eventCategory: "Shop"; eventLabel: string }
    | {
          eventAction: "Purchase Completed";
          eventCategory: "Shop";
          eventLabel: string;
          eventValue: number;
      };

export type userAction = userActions | feedbackActions | shopActions | lessonsActions | practiceActions;

export interface ITrackerContext {
    trackAction: (action: userAction) => void;
}

const TrackerContext = React.createContext<ITrackerContext>({} as any);

export const useTracker = () => useContext(TrackerContext);

interface ITrackerProviderProps {
    children: any;
}

export const TrackerProvider = ({ children }: ITrackerProviderProps) => {
    const store = useGlobalStore();
    const { isAuthenticated, profile } = useAuth0();
    const { pathname } = useLocation();

    const tagManagerArgs = {
        gtmId: store.settings?.GoogleTagManager ?? "",
        auth: store.settings?.GoogleTagManagerAuth ?? "",
    };

    useEffect(() => {
        if (tagManagerArgs.gtmId) TagManager.initialize(tagManagerArgs);
    }, []);

    useEffect(() => {
        trackPage(pathname);
    }, [pathname]);

    useEffect(() => {
        if (isAuthenticated && profile) {
            let prof = getUserData();
            track(prof);

            if (prof.user_logins_count === "1") {
                trackAction({ eventAction: "Signed Up", eventCategory: "User" });
            } else {
                trackAction({ eventAction: "Signed In", eventCategory: "User" });
            }
        }
    }, [isAuthenticated, profile]);

    const track = (data: Object) => {
        TagManager.dataLayer({
            dataLayer: data,
        });
    };

    const getUserData = () => {
        let createdat = Math.round(new Date(profile.created_at).getTime() / 1000);
        return {
            user_id: profile.user_id,
            user_name: profile.name,
            user_email: profile.email,
            user_logins_count: (profile as any).logins_count,
            user_created_at: profile.created_at,
            user_created_at_unix: createdat,
            user_app_version: store.appVersion,
        };
    };

    const trackPage = (path: string) => {
        track({
            event: "pageView",
            view_path: pathname,
            view_url: window.location.href,
        });
    };
    const trackAction = (action: userAction) => {
        let eventId = action.eventAction.replace(" ", "_").toLowerCase();
        track({
            event: "user_action",
            eventId,
            ...action,
        });
    };

    return <TrackerContext.Provider value={{ trackAction }}>{children}</TrackerContext.Provider>;
};

import { useCallback, useReducer } from "react";
import { useAuth0 } from "../auth/auth0Context";
import { APP_SETTINGS } from "../common/appSettings";
import axios from "axios";
import { useGlobalStore } from "../storage/GlobalStoreContext";

export enum PostState {
    None = "NONE",
    Sending = "SENDING",
    Success = "SUCCESS",
    Error = "ERROR",
}

export interface IApiPostResponse<T> {
    status: PostState;
    error: Error;
    data: T;
}

type Action<R> =
    | { type: PostState.Sending }
    | { type: PostState.Success; payload: R }
    | { type: PostState.Error; payload: Error };

export const useFetchPostApiData = <Tpost, Tresponse>(
    apiUrl: string,
): [IApiPostResponse<Tresponse>, (data: Tpost) => Promise<Tresponse>] => {
    const { idToken } = useAuth0();
    const settings = useGlobalStore();

    const stateReducer = (
        state: IApiPostResponse<Tresponse>,
        action: Action<Tresponse>,
    ): IApiPostResponse<Tresponse> => {
        switch (action.type) {
            case PostState.Sending:
                return { ...state, status: PostState.Sending };
            case PostState.Success:
                return { ...state, status: PostState.Success, data: action.payload };
            case PostState.Error:
                return { ...state, status: PostState.Error, error: action.payload };
            default:
                return state;
        }
    };

    const [state, dispatch] = useReducer<React.Reducer<IApiPostResponse<Tresponse>, Action<Tresponse>>>(stateReducer, {
        status: PostState.None,
        error: new Error(),
        data: {} as Tresponse,
    });

    const postData = useCallback(
        async (postData: Tpost): Promise<Tresponse> => {
            dispatch({ type: PostState.Sending });

            try {
                const response = await axios.post(`${APP_SETTINGS.APIUrl}${apiUrl}`, postData, {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json; charset=utf-8",
                        ...(idToken && { Authorization: "Bearer " + idToken }),
                        ...(settings.tenantId && { "x-tenant-id": settings.tenantId }),
                    },
                });

                var data = response.data;

                dispatch({ type: PostState.Success, payload: data });

                return data;
            } catch (error) {
                console.error(error);
                dispatch({ type: PostState.Error, payload: error });
            }

            return {} as Tresponse;
        },
        [apiUrl, idToken, settings.tenantId],
    );

    return [state, postData];
};

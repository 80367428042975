import { useGlobalStore } from "./../storage/GlobalStoreContext";
import { useEffect, useReducer } from "react";
import { useAuth0 } from "../auth/auth0Context";
import { APP_SETTINGS } from "../common/appSettings";
import axios from "axios";

export enum FetchStatus {
    Loading = "LOADING",
    Success = "SUCCESS",
    Error = "ERROR",
}

interface IFetchState<T> {
    status: FetchStatus;
    error: Error;
    data: T;
}

type Action<T> =
    | { type: FetchStatus.Loading }
    | { type: FetchStatus.Success; payload: T }
    | { type: FetchStatus.Error; payload: Error };

export const useFetchApiData = <T>(apiUrl: string, tenantId?: string): [FetchStatus, Error, T] => {
    const { idToken } = useAuth0();
    const settings = useGlobalStore();

    const stateReducer = <T>(state: IFetchState<T>, action: Action<T>): IFetchState<T> => {
        switch (action.type) {
            case FetchStatus.Loading:
                return { ...state, status: FetchStatus.Loading };
            case FetchStatus.Success:
                return { ...state, status: FetchStatus.Success, data: action.payload };
            case FetchStatus.Error:
                return { ...state, status: FetchStatus.Error, error: action.payload };
            default:
                return state;
        }
    };

    const [state, dispatch] = useReducer<React.Reducer<IFetchState<T>, Action<T>>>(stateReducer, {
        status: FetchStatus.Loading,
        error: new Error(),
        data: {} as T,
    });

    useEffect(() => {
        const source = axios.CancelToken.source();

        if (!apiUrl) return;

        const fetchData = async () => {
            dispatch({ type: FetchStatus.Loading });
            try {
                let xTenantId = tenantId ?? settings.tenantId;

                const response = await axios.get(`${APP_SETTINGS.APIUrl}${apiUrl}`, {
                    cancelToken: source.token,
                    headers: {
                        Accept: "application/json",
                        ...(idToken && { Authorization: "Bearer " + idToken }),
                        ...(xTenantId && { "x-tenant-id": xTenantId }),
                    },
                });

                const data = await response.data;

                dispatch({ type: FetchStatus.Success, payload: data });
            } catch (error) {
                if (axios.isCancel(error)) return;
                console.error(error);
                dispatch({ type: FetchStatus.Error, payload: error });
            }
        };

        fetchData();

        return () => {
            source.cancel();
        };
    }, [apiUrl, idToken, settings.tenantId, tenantId]);

    return [state.status, state.error, state.data];
};

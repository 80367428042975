import React, { useState, FunctionComponent } from "react";
import {
    Dialog,
    DialogContent,
    Button,
    useMediaQuery,
    useTheme,
    MobileStepper,
    makeStyles,
    createStyles,
    Theme,
    Grid,
    Typography,
    SvgIconProps,
} from "@material-ui/core";

import { StoreActions, useGlobalStore, useGlobalStoreDispatch } from "../../storage/GlobalStoreContext";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        stepper: {
            flexGrow: 1,
            backgroundColor: theme.palette.background.paper,
        },
        dialogPaper: {},
        slideRoot: {
            width: "100%",
            maxWidth: "400px",
        },
        sliderIcon: {
            width: 150,
            height: 150,
        },
    }),
);

interface IOnBoardingDialogProps {
    settingsKey: string;
    onDismiss?: () => void;
}

export const OnBoardingDialog: React.FunctionComponent<IOnBoardingDialogProps> = ({
    settingsKey,
    onDismiss,
    children,
}) => {
    const classes = useStyles();

    const settingsName = `app.onboarding.show.${settingsKey}`;

    const { appSettings } = useGlobalStore();
    const dispatch = useGlobalStoreDispatch();

    const [isOpen, setOpen] = useState<boolean>((appSettings[settingsName] as boolean) ?? true);
    const [activeStep, setActiveStep] = useState(0);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    let childrenCount = React.Children.count(children) - 1;

    const handleClose = () => {
        setOpen(false);

        dispatch({ type: StoreActions.SetAppSettings, payload: { [settingsName]: false } });

        if (onDismiss) onDismiss();
    };

    return (
        <>
            <Dialog
                open={isOpen}
                fullScreen={fullScreen}
                fullWidth
                maxWidth="sm"
                classes={{ paper: classes.dialogPaper }}
            >
                <DialogContent>
                    <>
                        <Grid container direction="column" spacing={2} alignItems="center">
                            <Grid item>
                                {React.Children.map(children, (child, index) => {
                                    if (activeStep === index) return child;
                                })}
                            </Grid>
                            <Grid item>
                                <MobileStepper
                                    className={classes.stepper}
                                    variant="dots"
                                    steps={childrenCount + 1}
                                    activeStep={activeStep}
                                    position="static"
                                    nextButton={null}
                                    backButton={null}
                                ></MobileStepper>
                            </Grid>
                            <Grid item>
                                {activeStep < childrenCount && (
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        size="large"
                                        onClick={() => setActiveStep((prevActiveStep) => prevActiveStep + 1)}
                                    >
                                        Next
                                    </Button>
                                )}
                                {activeStep === childrenCount && (
                                    <Button variant="outlined" color="primary" size="large" onClick={handleClose}>
                                        Close
                                    </Button>
                                )}
                            </Grid>
                        </Grid>
                    </>
                </DialogContent>
            </Dialog>
        </>
    );
};

export const OnBoardingSlide: FunctionComponent<{
    title: string;
    text: string;
    svgIcon: React.ReactElement<SvgIconProps>;
}> = ({ title, text, svgIcon, children }) => {
    const classes = useStyles();

    return (
        <div className={classes.slideRoot}>
            <Grid container direction="column" alignItems="center" spacing={2}>
                <Grid item>{React.cloneElement(svgIcon, { className: classes.sliderIcon })}</Grid>
                <Grid item>
                    <Typography variant="h6">{title}</Typography>
                </Grid>
                <Grid item>
                    <Typography variant="body1" align="center">
                        {text}
                    </Typography>
                </Grid>
                <Grid item>{children}</Grid>
            </Grid>
        </div>
    );
};

import React, { useEffect } from "react";
import { ResultAnswersComponent } from "./ResultAnswersComponent";
import { ResultTimeScoreComponent } from "./ResultTimeScoreComponent";
import { ResultQuestionsComponent } from "./ResultQuestionsComponent";
import { CategoryResultsComponent } from "./CategoryResultsComponent";
import { Grid, Typography } from "@material-ui/core";
import { ProgressComponent } from "../../components/Shared/Progress/ProgressComponent";
import { ErrorComponent } from "../error/ErrorComponent";
import { useParams } from "react-router-dom";
import { useGetTestSessionData } from "../../services/testSession.service";
import { StoreActions, useGlobalStoreDispatch } from "../../storage/GlobalStoreContext";
import { FetchStatus } from "../../services/useFetchApiData";

export const ResultContainer = () => {
    const { id } = useParams<{ id: string }>();

    let param = id ? id : "";

    const [status, error, testSessionResult] = useGetTestSessionData(param);

    const dispatch = useGlobalStoreDispatch();

    useEffect(() => {
        dispatch({ type: StoreActions.SetPageTitle, payload: "Test Summary" });
    }, [dispatch]);

    return (
        <>
            <Grid justify="center" container spacing={4} direction="column">
                {status === FetchStatus.Loading ? (
                    <ProgressComponent text="Fetching your results" />
                ) : status === FetchStatus.Error ? (
                    <ErrorComponent error={error} />
                ) : (
                    <>
                        <Grid item xs>
                            {testSessionResult && <Typography variant="h4">Test session results</Typography>}
                        </Grid>
                        <Grid item container xs spacing={4} alignItems="baseline">
                            <Grid item xs>
                                {testSessionResult && <ResultAnswersComponent result={testSessionResult} />}
                            </Grid>
                            <Grid item xs>
                                {testSessionResult && (
                                    <CategoryResultsComponent categories={testSessionResult.Categories} />
                                )}
                            </Grid>
                        </Grid>
                        <Grid item xs>
                            {testSessionResult && testSessionResult.Categories[0] && (
                                <ResultTimeScoreComponent
                                    testSessionResult={testSessionResult.Categories}
                                    gotoQuestion={() => null}
                                />
                            )}
                        </Grid>

                        {testSessionResult &&
                            testSessionResult.Categories.map((category, index) => (
                                <React.Fragment key={index}>
                                    <Grid item xs container spacing={3} alignItems="center">
                                        <Grid item xs={6}>
                                            <Typography variant="h5">{category.DisplayName} details</Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Typography variant="body2" align="right">
                                                Score
                                            </Typography>
                                            <Typography gutterBottom align="right">
                                                {category.CorrectNormalized}%
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Typography variant="body2" align="right">
                                                Total time spent
                                            </Typography>
                                            <Typography gutterBottom align="right">
                                                {category.TotalTimeSpentS}s
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs>
                                        <ResultQuestionsComponent testSessionResult={category} />
                                    </Grid>
                                </React.Fragment>
                            ))}
                    </>
                )}
            </Grid>
        </>
    );
};

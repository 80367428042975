import React, { useState, useEffect } from "react";

interface ICountdownProps {
    start: boolean;
    sec: number;
    timerTick: (sec: number) => void;
}

export const CountdownComponent = ({ start, sec, timerTick }: ICountdownProps) => {
    const [seconds, setSeconds] = useState(sec);

    useEffect(() => {
        let interval: any = null;

        if (start) {
            interval = setInterval(() => {
                setSeconds((seconds) => seconds - 1);
            }, 1000);
        } else {
            clearInterval(interval);
        }

        if (start === false) {
            clearInterval(interval);
        } else {
            timerTick(seconds);
        }
        return () => clearInterval(interval);
    }, [seconds, start]);

    const parseString = (countdown: number) => {
        let minutes = Math.floor(Math.abs(countdown) / 60);
        let seconds = Math.abs(countdown) - minutes * 60;
        let sign = countdown < 0 ? "-" : "";

        return (
            sign + (minutes < 10 ? "0" : "") + minutes.toString() + ":" + (seconds < 10 ? "0" : "") + seconds.toString()
        );
    };

    return <>{parseString(seconds)}</>;
};

import React from "react";
import { makeStyles, Container } from "@material-ui/core";
import { Route, Redirect, Switch } from "react-router-dom";
import { AppBarComponent } from "../components/AppBar/AppBarComponent";
import { SideBarComponent } from "../components/SideBar/SideBarComponent";
import { InsightsContainer } from "./insights/InsightsContainer";
import { LessonPlayContainer } from "./lessons/Play/LessonPlayContainer";
import { LessonsContainer } from "./lessons/LessonsContainer";
import { SettingsContainer } from "./settings/SettingsContainer";
import { PracticeContainer } from "./practice/PracticeContainer";
import { TimelineContainer } from "./timeline/TimelineContainer";
import { ShopContainer } from "./shop/ShopContainer";
import { ReceiptContainer } from "./shop/Receipt/ReceiptContainer";
import { ResultContainer } from "./result/ResultContainer";
import { useGlobalStore } from "../storage/GlobalStoreContext";
import { WelcomeDialog } from "../components/OnBoarding/WelcomeDialog";

const useStyles = makeStyles((theme) => ({
    appBarSpacer: { ...theme.mixins.toolbar },

    content: {
        flex: 1,
        padding: theme.spacing(0),
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },

    container: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
}));

export const CoreLayout = () => {
    const classes = useStyles();

    const globalStore = useGlobalStore();

    const logoPath = globalStore.settings.Logo;
    const [state, setState] = React.useState({ mobileSideBarOpen: false });

    const toggleMobileSideBar = (open: boolean) => () => {
        setState({ mobileSideBarOpen: open });
    };

    return (
        <>
            <AppBarComponent
                logoPath={logoPath ?? ""}
                toggleMobileSideBar={toggleMobileSideBar}
                pageTitle={globalStore.pageTitle}
            />

            <SideBarComponent
                logoPath={globalStore.settings.Auth0Logo ?? ""}
                toggleMobileSideBar={toggleMobileSideBar}
                mobileSideBarOpen={state.mobileSideBarOpen}
            />

            <main className={classes.content}>
                <div className={classes.appBarSpacer} />

                <Container className={classes.container}>
                    <Switch>
                        <Route exact path="/" render={() => <Redirect to="/practice" />} />
                        <Route path="/practice/result/:id" component={ResultContainer} />
                        <Route path="/practice" component={PracticeContainer} />
                        <Route path="/shop/payment/callback/:provider/:sessionId" component={ReceiptContainer} />
                        <Route path="/shop/receipt/:sessionId/:isCallback?" component={ReceiptContainer} />
                        <Route path="/shop" component={ShopContainer} />
                        <Route path="/timeline" component={TimelineContainer} />
                        <Route path="/settings" component={SettingsContainer} />
                        <Route path="/insights" component={InsightsContainer} />
                        <Route path="/lessons/play/:id" component={LessonPlayContainer} />
                        <Route path="/lessons" component={LessonsContainer} />
                        <Redirect to="/" /> // Redirect all 404's to home
                    </Switch>
                    <WelcomeDialog />
                </Container>
            </main>
        </>
    );
};
